
/*==============================================
    Portfolio Filter
===============================================*/
// style1 
.masonry-filter,
.masonry-filter-2 {
    li {
        display: inline-block;
        margin-left: -4px;
        &:first-child {
            margin-left: 0;
        }
        a {
            display: block;
        }
    }
    &.style1 {
        border-radius: 5px;
        margin-bottom: 80px;
        li {
            a {
                background-color: rgba($light, 0.1);
                border: 1px solid rgba($light, 0.2);
                border-left: 0;
                color: $light;
                font-size: 12px;
                line-height: 29px;
                padding: 0 33px;
            }
            &:first-child {
                a {
                    border-radius: $border-radius 0 0 $border-radius;
                    border-left: 1px solid rgba($light, 0.2);
                }
            }
            &:last-child {
                a {
                    border-radius: 0 $border-radius $border-radius 0;
                }
            }
        }
        .active a {
            background-color: $light;
            color: $title-color;
        }
        a:hover, a.active {
            background-color: $light;
            color: $title-color;
        }
        &.dark {
            li {
                a {
                    border-color: $border-color;
                    color: $title-color;
                }
                &:last-child {
                    a {
                        border-right-color: $border-color;
                    }
                }
                a:hover, a.active {
                    background-color: $primary-color;
                    border-color: $primary-color;
                    color: $light;
                }
            }
        }
    }
    &.style-2 {
        margin-bottom: 90px;
        li {
            margin-left: -3px;
            a {
                background-color: $gray-bg-2;
                color: #444;
                font-size: 10px;
                font-weight: 400;
                padding: 5px 40px;
                text-transform: uppercase;
            }
            .active {
                background-color: $primary-color;
                color: $light;
            }
            &:first-child {
                a {
                    border-bottom-left-radius: 3px;
                    border-top-left-radius: 3px;
                }
            }
            &:last-child {
                a {
                    border-bottom-right-radius: 3px;
                    border-top-right-radius: 3px;
                }
            }
        }
    }

    &.style-3,
    &.style-4 {
        border-bottom: 1px solid $border-color;
        li {
            a {
                color: $title-color;
                font-size: 12px;
                padding: 0 15px 20px;
                position: relative;
                &::before {
                    background-color: $primary-color;
                    content: "";
                    height: 2px;
                    position: absolute;
                    left: 0;
                    bottom: -1px;
                    width: 0;
                    @include transition();
                }
            }
            .active::before {
                width: 100%;
            }
        }
        .active a::before {
            width: 100%;
        }
    }
    &.style-3.light {
        border-bottom-color: rgba($light, 0.3);
        li a {
            color: $light;
        }
    }
    &.style-4 {
        li a::before {
            background-color: $title-color;
        }
    }

    &.style-5 {
        li a {
            border-radius: $border-radius;
            color: $text-color;
            line-height: 1;
            padding: 8px 18px;
            &:hover, &.active {
                background-color: $primary-color;
                color: $light;
            }
        }
    }

    &.style-6 {
        li {
            a {
                border: 1px solid $border-color;
                border-left: 0;
                color: $text-color;
                line-height: 29px;
                padding: 0 33px;
                &:hover, &.active {
                    background-color: $title-color;
                    border-color: $title-color;
                    color: $light;
                }
            }
            &:first-child {
                a {
                    border-radius: 15px 0 0 15px;
                    border-left: 1px solid $border-color;
                }
            }
            &:last-child {
                a {
                    border-radius: 0 15px 15px 0;
                }
            }
        }
    }

    &.style-7 {
        li {
            a {
                color: $text-color;
                line-height: 1;
                padding: 17px 15px 11px;
                border-left: 1px solid transparent;
                border-right: 1px solid transparent;
                position: relative;
                &:hover {
                    color: $title-color;
                }
                &.active {
                    background-image: -moz-linear-gradient( 90deg, $light 0%, #f3f4f4 65%, #e7e8e9 100%);
                    background-image: -webkit-linear-gradient( 90deg, $light 0%, #f3f4f4 65%, #e7e8e9 100%);
                    background-image: -ms-linear-gradient( 90deg, $light 0%, #f3f4f4 65%, #e7e8e9 100%);
                    background-image: linear-gradient( 0deg, $light 0%, #f3f4f4 65%, #e7e8e9 100%);
                    border-color: $border-color;
                    color: $title-color;
                    &::before {
                        border: 1px solid $light;
                        bottom: -2px;
                        content: "";
                        left: 0;
                        position: absolute;
                        width: 100%;
                    }
                }
            }
            &:first-child a {
                border-left: 1px solid transparent;
            }
        }
    }
}

.filter-with-actions {
    border-bottom: 1px solid $border-color;
    .masonry-filter {
        border-bottom: 0;
    }
}

.portfolio-heading {
    padding-bottom: 50px;
    padding-top: 40px;
    position: relative;
}
.portfolio-toggle {
    bottom: 0;
    left: 50%;
    position: absolute;
    @include transform (translate(-50%, 50%));
}

/*==============================================
    Portfolio
===============================================*/

.portfolio-carousel {
    .owl-prev, .owl-next {
        position: absolute;
        top: 50%;
        left: 10%;
        @include transform(translateY(-50%));
    }
    .owl-next {
        left: auto;
        right: 10%;
    }
}

.portfolio-carousel-3-col {
    .owl-prev,
    .owl-next {
        position: absolute;
        top: 50%;
    }
    .owl-prev {
        left: 0;
        @include transform (translate(-50%, -50%));
    }
    .owl-next {
        right: 0;
        @include transform (translate(50%, -50%));
    }
}

.portfolio-wrapper {
    background-color: #212121;
    .portfolio {
        margin-bottom: 30px;
    }
}

/*----------------------------------------
    Portfolio type-1
------------------------------------------*/

.portfolio {
    position: relative;
    margin-bottom: 30px;
    &.mb-0 {
        margin-bottom: 0;
    }
    .portfolio-center {
        height: 100%;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        &::after {
            content: "";
            display: inline-block;
            height: 100%;
            vertical-align: middle;
        }
    }
    .portfolio-center-inner {
        display: inline-block;
        vertical-align: middle;
        max-width: 100%;
    }
    &.type-1 {
        text-align: center;
        .pf-thumb {
            position: relative;
            img {
                margin: 0 auto;
                width: 100%;
                display: block;
            }
        }
        .hover {
            background-color: rgba($primary-color, 0.9);
            bottom: 20px;
            left: 20px;
            opacity: 0;
            position: absolute;
            right: 20px;
            text-align: center;
            top: 20px;
            @include transition(opacity 0.3s ease 0s);
            &::after {
                content: "";
                display: inline-block;
                height: 100%;
                vertical-align: middle;
            }
        }
        .hover-inner {
            display: inline-block;
            max-width: 100%;
            vertical-align: middle;
        }
        a {
            margin: 0 6px;
        }
        h5 {
            color: $light;
            font-size: 16px;
            margin-top: 25px;
            text-transform: uppercase;
        }
        &:hover {
            .hover {
                opacity: 1;
            }
        }
    }

    /*----------------------------------------
        Portfolio type-2
    ------------------------------------------*/
    &.type-2 {
        background-color: #f5f2f2;
        padding: 30px;
        text-align: left;
        figure {
            min-height: 520px;
            position: relative;
            img {
                bottom: 0;
                left: 0;
                margin: auto;
                position: absolute;
                right: 0;
                top: -50px;
            }
        }
        figcaption {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
        }
        .cat {
            font-weight: 700;
        }
        h4 {
            a {
                color: $title-color;
                @include flexbox();
                @include align-items(center);
                &:hover {
                    color: $primary-color;
                    i {
                        @include transform(translateX(5px));
                    }
                }
            }
            i {
                @include transition(all 0.3s ease 0s);
            }
        }
    }
    /*----------------------------------------
        Portfolio type-3
    ------------------------------------------*/
    &.type-3 {
        background-color: #fff;
        @include transition(box-shadow 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s);
        .actions {
            left: 0;
            position: absolute;
            right: 0;
            text-align: center;
            top: 50%;
            @include transform(scale(0) translateY(-50%));
            @include transition(all 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s);
            a {
                margin: 0 4px;
            }
            i {
                display: block;
                margin: 0;
            }
        }
        .caption {
            padding: 13px 20px 15px;
            position: relative;
            p {margin: 0;}
            .more-vert {
                position: absolute;
                right: 25px;
                top: 50%;
                @include transform(translateY(-50%));
                & > a {
                    box-shadow: none;
                    &:hover {
                        box-shadow: none;
                    }
                }
            }
        }
        &:hover {
            box-shadow: $box-shadow-1;
            .hover-ripple {
                @include transform(scale(6));
            }
            .actions {
                @include transform(scale(1) translateY(-50%));
            }
        }
    }
    &.type-4 {
        img {
            width: 100%;
        }
    }
    &.type-5,
    &.type-6,
    &.type-7 {
        margin: 0 7.5px 15px;
        &.mb-30 {
            margin: 0 0 30px 0;
        }
        img {
            width: 100%;
        }
        h3 {
            font-size: 24px;
            margin-bottom: 6px;
        }
        h3, p {
            color: $light;
            letter-spacing: 2.8px;
        }
        .hover-ripple {
            opacity: .7;
            @include transition (all 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0s);
        }
        .hover {
            opacity: 0;
            @include transform(scale(0));
            @include transition (all 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0s);
        }
        &:hover, &.hover, &.slick-active.slick-center {
            .hover-ripple {
                @include transform(scale(11));
            }
            .hover {
                opacity: 1;
                @include transform(scale(1));
            }
        }
    }
    &.type-6 {
        margin: 0;
        .hover a {
            color: $light;
            font-size: 40px;
        }
    }
    &.type-7 {
        margin: 0 0 30px 0;
    }

    &.type-8 {
        img {
            width: 100%;
        }
        .overlay {
            background: -webkit-linear-gradient(-35deg, rgba($dark, 0.5) 50%, rgba($primary-color, 0.5) 50%) repeat scroll 0 0;
            background:    -moz-linear-gradient(-35deg, rgba($dark, 0.5) 50%, rgba($primary-color, 0.5) 50%) repeat scroll 0 0;
            background:      -o-linear-gradient(-35deg, rgba($dark, 0.5) 50%, rgba($primary-color, 0.5) 50%) repeat scroll 0 0;
            background:         linear-gradient(-35deg, rgba($dark, 0.5) 50%, rgba($primary-color, 0.5) 50%) repeat scroll 0 0;
            opacity: 0;
        }

        .hover- {
            bottom: 0;
            left: 50px;
            position: absolute;
            right: 50px;
            z-index: 1;
            @include transform (translateY(100%));
        }

        .image-popup {
            border: 1px solid;
            border-radius: 5px;
            color: $light;
            font-size: 30px;
            left: 50%;
            line-height: 60px;
            position: absolute;
            text-align: center;
            top: 50%;
            width: 60px;
            z-index: 1;
            @include transform (translate(-50%, -50%) scale(0));
        }

        &:hover {
            .overlay {
                opacity: 1;
            }

            .image-popup {
                @include transform (translate(-50%, -50%) scale(1));
            }

            .hover- {
                bottom: 50px;
                @include transform (translateY(0px));
            }
        }
    }

    &.type-9 {
        padding: 25px 20px;
        img {
            width: 100%;
        }
        &:hover {
            img {
                @include transform (rotate(-3deg));
            }
        }
    }

    &.style-10 {
        + .portfolio {
            margin-top: 50px;
        }
        img {
            width: 100%;
        }
        .actions {
            background-color: rgba($light, 0.95);
            bottom: 30px;
            font-size: 24px;
            left: 0;
            padding-bottom: 15px;
            padding-top: 15px;
            position: absolute;
            right: 0;
            opacity: 0;
            @include transition();
        }
        &:hover {
            .actions {
                opacity: 1;
                bottom: 50px;
            }
        }
    }

    &.style-11 {
        img {
            outline: 0px solid $light;
            outline-offset: -10px;
            width: 100%;
        }
        figcaption {
            background-color: rgba($light, 0.8);
            bottom: 0;
            left: 0;
            padding: 30px 50px;
            position: absolute;
            right: 0;
            @include transform(translateY(100%));
        }
        .link {
            background-color: $primary-color;
            border-radius: 30px;
            color: $light;
            height: 40px;
            line-height: 43px;
            opacity: 0;
            position: absolute;
            right: 10px;
            text-align: center;
            top: 10px;
            width: 40px;
        }
        &:hover {
            box-shadow: $box-shadow-1;
            img {
                outline: 10px solid $light;
            }
            figcaption {
                @include transform(translateY(0px));
            }
            .link {
                opacity: 1;
            }
        }
    }

    &.style-12 {
        .caption {
            background-color: rgba(#fff, 0.95);
            height: 100%;
            max-width: 520px;
            padding: 0 80px;
            position: absolute;
            right: 0;
            top: 0;
            @include flexbox();
            @include align-items(center);
            @include transform(translateX(100%));
            i {
                font-size: 48px;
            }
            i, h3, p, .btn-large {
                position: relative;
                left: 30px;
                opacity: 0;
            }
            i {
                @include transition(all 0.3s ease 0.2s);
            }
            h3 {
                @include transition(all 0.3s ease 0.3s);
            }
            p {
                @include transition(all 0.3s ease 0.4s);
            }
            .btn-large {
                @include transition(all 0.3s ease 0.5s);
            }
        }
        &:hover {
            .caption {
                @include transform(translateX(0));
                i, h3, p, .btn-large {
                    left: 0;
                    opacity: 1;
                }
            }
        }
    }

    &.style-13 {
        .overlay,
        .container {
            opacity: 0;
            visibility: hidden;
        }
        .icon {
            font-size: 48px;
        }
        &:hover {
            .overlay,
            .container {
                opacity: 0.8;
                visibility: visible;
            }   
        }
    }

    &.style-14 {
        &:hover {
            .thumb {
                box-shadow: $hover-shadow;
            }
            h4 a {
                color: $primary-color;
            }
        }
    }
}

.col-lg-3 {
    .portfolio.style-11 figcaption {
        padding: 20px 30px;
    }
}
.col-lg-2 {
    .portfolio.style-11 figcaption {
        padding: 14px 30px 16px;
        @include transform(translateY(0px));
    }
}

.masonry.fullwidth-gutter {
    margin-left: -7.5px;
    margin-right: -7.5px;
    & > [class^="col"] {
        padding-right: 7.5px;
        padding-left: 7.5px;
    }
}

.isotope-gutter {
    [class^="col-"]:nth-last-child(1),
    [class^="col-"]:nth-last-child(2), {
        .portfolio {
            margin-bottom: 0;
        }
    }
}

/*==============================================
    cp Gallery
===============================================*/
.cp-gallery {
    position: relative;
    overflow: hidden;
    figcaption {
        bottom: 40px;
        left: 50px;
        opacity: 0;
        position: absolute;
        right: 50px;
        @include transform(scale(0));
        @include transform-origin(left bottom 0);
        @include transition(all 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s);
        span {
            color: $primary-color;
        }
        h3 {
            color: $light;
        }
    }
    &:hover {
        .hover-ripple {
            @include transform(scale(7));
        }
        figcaption {
            opacity: 1;
            @include transform(scale(1));
        }
    }
}

/*==============================================
    Image Gallery
===============================================*/
.image-gallery-1 {
    .owl-prev {
        left: 10px;
    }
    .owl-next {
        right: 10px;
    }
}

.expander-details-wrapper {
    display: none;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
    &.static {
        position: static;
    }
}
.expander-header {
    border-bottom: 1px solid $border-color;
    padding-bottom: 16px;
}
.popup-portfolio {
    .expander-header {
        border-bottom: 1px solid rgba(#fff, 0.1);
    }
    .mfp-close {
        display: none;
    }
    .expander-close {
        &::before {
            background-color: #fff;
        }
        &:hover {
            color: $text-light;
        }
    }
}
.expander-actions {
    a {
        color: $text-color;
        display: block;
        font-size: 24px;
        height: 30px;
        line-height: 30px;
        position: relative;
        text-align: center;
        width: 55px;
        &::before {
            background-color: $dark;
            bottom: -17px;
            content: "";
            height: 2px;
            left: 0;
            position: absolute;
            width: 0;
            @include transition();
        }
        &:hover {
            color: $title-color;
            &::before {
                width: 100%;
            }
        }
    }
    .expander-prev {

    }
    .expander-next {

    }
    .expander-close {
        margin-right: 0;
    }
}

.pf-carousel-3col,
.pf-slider-1col {
    margin-left: -15px;
    margin-right: -15px;
    .portfolio {
        margin-left: 15px;
        margin-right: 15px;
    }
    .btn-large {
        position: absolute;
        top: 50%;
    }
    .slick-prev {
        left: 15px;
        @include transform(translate(-50%, -50%));
    }
    .slick-next {
        right: 15px;
        @include transform(translate(50%, -50%));
    }
}
.pf-carousel-3col {
    .portfolio.type-7 {
        .hover-ripple {
            @include transform(scale(11));
        }
        &.slick-active.slick-center {
            .hover-ripple {
                @include transform(scale(0));
            }
        }
    }
    .slick-arrow {
        background-color: #fff;
        opacity: 0;
        i {
            color: #ccc;
        }
        &:hover {
            background-color: $primary-color;
            opacity: 1;
            i {
                color: #fff;
            }
        }
    }
    &:hover {
        .slick-arrow {
            opacity: 1;
        }
    }
}
.pf-slider-1col {
    margin-left: auto;
    margin-right: auto;
    .item {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 400px;
        position: relative;
    }
    .portfolio {
        margin-left: auto;
        margin-right: auto;
    }
}
.portfolio-meta {
    li {
        font-size: 18px;
        font-weight: 300;
        line-height: 1.7;
        span {
            display: inline-block;
            font-weight: 400;
            min-width: 130px;
        }
    }
}
