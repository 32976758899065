
/*==============================================
    Footer Widgets
===============================================*/
.widget-toggle-btn {
    left: 50%;
    padding: 0 !important;
    position: absolute;
    top: 0;
    @include transform (translate(-50%, -50%));
    &.show-widget {
        display: none
    }
    .fa-angle-up {
        display: none;
    }
    &.active {
        .fa-angle-down {
            display: none;
        }
        .fa-angle-up {
            display: inline-block;
        }
    }
}

.footer-widgets {
    &.type-1 {
        background-color: $title-color;
    }
    &.bg-color-2 {
        background-color: #21282f;
    }
}

.footer-widget-3 {
    .social {
        float: left;
        margin-right: 50px;
        width: 85px;
        li {
            display: inline-block;
            a {
                background-color: rgba($dark, 0.15);
                color: $light;
                display: block;
                height: 40px;
                line-height: 40px;
                margin-bottom: 3px;
                text-align: center;
                width: 40px;
                &:hover {
                    background-color: $title-color;
                }
            }
        }
    }
    .about {
        float: left;
        margin-top: -6px;
        @include calc( width, "100% - 135px" );
    }
}

.footer-nav-3 {
    & > li {
        display: inline-block;
        & > a {
            color: $text-color;
            display: block;
            font-size: 13px;
            font-weight: 400;
            padding: 0 18px;
        }
        &:first-child > a {
            padding-left: 0;
        }
        &:last-child > a {
            padding-right: 0;
        }
    }
}

.social-profiles {
    li {
        display: inline-block;
        a {
            display: block;
            margin: 0 5px;
        }
        .twitter { background-color: $twitter;}
        .facebook { background-color: $facebook;}
        .dribbble { background-color: $dribbble;}
        .pinterest { background-color: $pinterest;}
        .behance { background-color: $behance;}
        .gplus { background-color: $gplus;}
    }
}
