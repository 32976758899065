
/*==============================================
    Social Links
===============================================*/

.social-links {
    li {
        display: inline-block;
        vertical-align: middle;
        a { display: block; }
    }
}

.social-1 {
    li {
        a {
            background-color: rgba($dark, 0.15);
            border-radius: 2px;
            color: $light;
            display: block;
            height: 40px;
            line-height: 40px;
            text-align: center;
            width: 40px;
            &:hover {
                background-color: rgba($dark, 0.54);
            }
        }
    }
}

.social-2 {
    li a {
        width: 40px;
        line-height: 40px;
        display: block;
        text-align: center;
        height: 40px;
        &:hover {
            color: $light;
        }
    }
}

/*==============================================
    Datetime picker
===============================================*/
.bootstrap-datetimepicker-widget {
    &.dropdown-menu {
        border: 0 none;
        border-radius: 0;
        box-shadow: $box-shadow-1;
        color: $title-color;
        font-family: $roboto-slab;
        margin: 0;
        min-width: 370px;
        padding: 35px;
    }
    &.dropdown-menu.top::before {
        border-top: 7px solid rgba($dark, 0.06);
    }
    thead {
        tr {
            .prev,
            .picker-switch,
            .next {
                font-weight: 300;
                height: auto;
                line-height: inherit;
                padding: 0 0 20px;
                &:hover {
                    background-color: transparent !important;
                }
            }
            .picker-switch {
                font-size: 18px;
            }
        }
        .dow {
            background-color: #f3f3f3;
            border-left: 2px solid #fff;
            font-weight: 300;
            margin-right: 2px;
            min-width: 38px;
            padding: 0;
            width: 38px;
        }
    }
    table {
        td {
            &.active, &.active:hover {
                background-color: $primary-color;
                text-shadow: none;
            }
            &.today::before {
                border-bottom-color: $primary-color;
            }
            span {
                border-radius: 0;
                height: 45px;
                line-height: 43px;
                margin: 1px;
                width: 45px;
                &:hover {
                    background-color: transparent;
                }
                &.active {
                    background-color: $primary-color;
                    text-shadow: none;
                }
            }
        }
        td, th, td.day {
            border-radius: 0;
            height: 40px;
            line-height: 40px;
            min-width: 40px;
            padding: 0;
            width: 40px;
        }
    }
    .timepicker-picker {
        a {
            background-color: $gray-bg-3;
            box-shadow: $box-shadow-1;
            color: $title-color;
            font-size: 18px;
            padding: 0;
            &:hover {
                background-color: $primary-color;
                color: $light;
            }
            span {
                height: auto;
                line-height: inherit;
                margin: 0;
            }
        }
        [data-action="togglePeriod"] {
            background-color: $gray-bg-3;
            box-shadow: $box-shadow-1;
            color: $title-color;
            font-size: 12px;
            height: 36px;
            padding: 0;
            width: 36px;
            &:hover {
                background-color: $primary-color;
                color: $light;
            }
        }
    }
    .timepicker-hour,
    .timepicker-minute,
    .timepicker-second {
        font-weight: 400;
    }
}

/*==============================================
    Devider
===============================================*/

.devider {
    &.style-1 {
        background-color: rgba($dark, 0.1);
        height: 1px;
        &.light {
            background-color: rgba($light, 0.1);
        }
    }
    &.style-2 {
        background-color: rgba($dark, 0.1);
        height: 1px;
        position: relative;
        .sep {
            background-color: $title-color;
            height: 3px;
            position: absolute;
            left: 50%;
            top: -1px;
            width: 100px;
            @include transform(translateX(-50%))
        }
        &.light {
            background-color: rgba($light, 0.1);
            .sep {
                background-color: rgba($light, .87);
            }
        }
    }
    &.style-3 {
        background: transparent url("../img/misc/devider-3.png") no-repeat scroll center center / cover;
        height: 4px;
    }
    &.style-4 {
        .sep {
            background-color: $title-color;
            display: block;
            height: 3px;
            width: 100px;
        }
        &.light {
            .sep {
                background-color: rgba($light, 0.54);
            }   
        }
    }
    &.style-5 {
        height: 3px;
        border-bottom: 3px dashed rgba($dark, 0.1);
        &.light {
            border-bottom-color: rgba($light, 0.1);
        }
    }
    &.style-6 {
        height: 3px;
        border-bottom: 3px dotted rgba($dark, 0.1);
        &.light {
            border-bottom-color: rgba($light, 0.1);
        }
    }
    &.style-7 {
        position: relative;
        &::before, &::after {
            background-color: rgba($dark, 0.1);
            content: "";
            height: 1px;
            margin-top: -1px;
            position: absolute;
            top: 50%;
            @include calc(width, "50% - 55px");
        }
        &::before {
            left: 0;
        }
        &::after {
            right: 0;
        }
        .check {
            border: 1px solid rgba($dark, 0.1);
            border-radius: 30px;
            display: inline-block;
            height: 40px;
            line-height: 38px;
            width: 40px;
        }
    }
}

/*==============================================
    List Style
===============================================*/

.list li::before {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    margin-right: 15px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.list-bullhorn li::before {
    content: "\f0a1";
}

.list-long-arrow li::before {
    content: "\f178";
}

.list-gavel li::before {
    content: "\f0e3";
}

.list-graduation li::before {
    content: "\f19d";
}

.list-heart li::before {
    content: "\f004";
}

.list-play li::before {
    content: "\f04b";
}

.list-leaf li::before {
    content: "\f06c";
}

.list-photo li::before {
    content: "\f03e";
}

.list-plane li::before {
    content: "\f072";
}

.list-taxi li::before {
    content: "\f1ba";
}

.list-trophy li::before {
    content: "\f091";
}

.list-wrench li::before {
    content: "\f0ad";
}

.list-hourglass li::before {
    content: "\f251";
}

.list-bed li::before {
    content: "\f236";
}

.list-life-buoy li::before {
    content: "\f1cd";
}

.list-folder li::before {
    content: "\f07b";
}

.list-caret > li {
    position: relative;
    &::before {
        content: "\f0da";
        display: inline-block;
        font: normal normal normal 16px/1 FontAwesome;
        font-size: inherit;
        margin-right: 15px;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale
    }
    &.active {
        color: $title-color;
        &::before {
            content: "\f0d7";
        }
    }
}

.list-group-item {
    background-color: $gray-bg-3;
    border: 0 none;
    padding: 10px 30px;
    &:first-child {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
    }
    &:nth-child(2n) {
        background-color: $gray-bg-4;
    }
    &:last-child {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }
    .badge {
        background-color: #717171;
        border-radius: 30px;
        color: #fff;
        float: right;
        font-weight: 400;
        height: 30px;
        line-height: 31px;
        padding: 0 15px;
        @include transition(all 0.3s ease 0s );
    }
    &:hover {
        .badge {
            background-color: $primary-color;
        }
    }
    &.active, .active:hover, .active:focus  {
        background-color: $primary-color;
        border-color: $primary-color;
        color: #fff;
        z-index: 2;
    }
    &.list-group-item-success {
        background-color: $success;
        color: $success-text;
    }
    &.list-group-item-info {
        background-color: $info;
        color: $info-text;
    }
    &.list-group-item-warning {
        background-color: $warning;
        color: $warning-text;
    }
    &.list-group-item-danger {
        background-color: $danger;
        color: $danger-text;
    }
}

/*==============================================
    Matex Label
===============================================*/

.mtx-label {
    border-radius: $border-radius;
    display: inline-block;
    font-weight: 500;
    height: 40px;
    line-height: 40px;
    padding: 0 30px;
    &.round {
        border-radius: 30px;
    }
    &.default {
        background-color: $gray-bg-3;
        color: $text-color;
    }
    &.primary {
        background-color: $primary-color;
        color: $light;
    }
    &.success {
        background-color: #6ab55b;
        color: $light;
    }
    &.info {
        background-color: #6fc0df;
        color: $light;
    }
    &.warning {
        background-color: #e8ac47;
        color: $light;
    }
    &.danger {
        background-color: #cf574b;
        color: $light;
    }
    &.absolute-right {
        position: absolute;
        right: 30px;
        top: 30px;
    }
    .text {
        position: absolute;
        right: 20px;
        top: 27px;
    }
    &.style-2,
    &.style-3 {
        background-color: transparent;
        height: 150px;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 150px;
        .shape-1 {
            border-color: transparent;
            border-style: solid;
            border-width: 75px;
            position: absolute;
            right: 0;
            top: 0;
        }
        &.primary {
            .shape-1 {
                border-color: $primary-color $primary-color transparent transparent;
            }
        }
    }
    &.style-3 {
        .shape-1 {
            right: -10px;
            top: -10px;
        }
        .shape-2 {
            border-color: transparent;
            border-style: solid;
            border-width: 10px;
            left: 0;
            position: absolute;
            top: -20px;
        }
        .shape-3 {
            border-color: transparent;
            border-style: solid;
            border-width: 10px;
            bottom: 0;
            position: absolute;
            right: -20px;
        }
        .text {
            top: 20px;
            right: 15px;
        }
        &.primary {
            .shape-2 {
                border-color: transparent transparent #af2b27;
            }
            .shape-3 {
                border-color: transparent transparent transparent #af2b27;
            }
        }
    }
}

/*==============================================
    Editable Field
===============================================*/

.editable-field {
    border-radius: 2px;
    padding: 10px 30px 12px;
    .popover {
        border-radius: $border-radius;
    }
    .popover-title {
        border-bottom: 1px solid $border-color;
        border-radius: 2px 2px 0 0;
    }
    .select2-input {
        height: auto;
    }
}

.selectize-dropdown,
.selectize-input,
.selectize-input input {
    color: $text-color;
}

.editableform {
    .form-control {
        border-bottom-color: $border-color;
        height: 35px;
        margin-bottom: 0;
        &:focus {
            box-shadow: none;
        }
    }
    .btn {
        height: auto;
        line-height: 1.5;
        padding: 5px 10px;
        width: auto;
        i {
            font-size: 12px;
        }
    }
}
.editable-checklist {
    input {
        height: auto;
        width: auto;
    }
    label {
        font-size: 14px;
        font-weight: 400;
    }
}
.editable-click,
a.editable-click,
a.editable-click:hover {
    border-bottom-color: $primary-color;
}

/*==============================================
    File Upload
===============================================*/
.upload-field {
    .form-control {
        border-color: $border-color;
        border-radius: 2px 0 0 2px;
        box-shadow: none;
        height: 40px;
        line-height: 40px;
        padding-bottom: 0;
        padding-top: 0;
        &:focus {
            
        }
    }
    .file-caption-name {
        height: 40px;
    }
    .btn {
        height: 40px;
        i {
            font-size: inherit;
        }
    }
}

/*==============================================
    Default Panels
===============================================*/

.panel {
    border: 0 none;
    border-radius: $border-radius;
    box-shadow: $box-shadow-1;
    &:last-child {
        margin-bottom: 0;
    }
    & > .panel-heading {
        background-color: $gray-bg-3;
        border-bottom: 0;
    }
}
.panel-heading {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    padding: 10px 30px;
}
.panel-body {
    padding: 23px 30px 26px;
}
.panel-primary > .panel-heading {
    background-color: $primary-color;
    color: $light;
}
.panel-success > .panel-heading {
    background-color: $success;
    color: $success-text;
}
.panel-info > .panel-heading {
    background-color: $info;
    color: $info-text;
}
.panel-warning > .panel-heading {
    background-color: $warning;
    color: $warning-text;
}
.panel-danger > .panel-heading {
    background-color: $danger;
    color: $danger-text;
}

/*==============================================
    Modal
===============================================*/

.modal {
    .modal-heading {
        padding-bottom: 31px;
        border-bottom: 1px solid $border-color;
    }
    .modal-content {
        padding: 40px 75px;
    }
    .modal-footer {
        background-color: transparent;
        padding: 0;
    }
}

.popover {
    border: 0;
    border-radius: $border-radius;
    box-shadow: $box-shadow-1;
    &.top > .arrow {
        border-bottom-width: 0;
        border-top-color: rgba($dark, 0.08);
    }
    &.right > .arrow {
        border-right-color: rgba($dark, 0.08);
    }
    &.bottom > .arrow {
        border-bottom-color: rgba($dark, 0.08);
    }
    &.left > .arrow {
        border-left-color: rgba($dark, 0.08);
    }
}
.popover-content {
    padding: 20px 30px 23px;
}

/*==============================================
    Pagination
===============================================*/

.pagination-1 {
    a {
        border: 1px solid $border-color;
        border-right: 0;
        color: $text-color;
        display: block;
        font-size: 12px;
        height: 30px;
        line-height: 29px;
        padding: 0 15px;
        &:hover {
            color: $title-color;
        }
        &.active {
            background-color: $primary-color;
            border-color: $primary-color;
            color: $light;
        }
    }
    li {
        &:first-child a {
            border-bottom-left-radius: $border-radius;
            border-top-left-radius: $border-radius;
        }
        &:last-child a {
            border-bottom-right-radius: $border-radius;
            border-right: 1px solid $border-color;
            border-top-right-radius: $border-radius;
        }
    }
}

.pagination-2 {
    a {
        background-color: $gray-bg-3;
        color: $text-color;
        display: block;
        font-size: 12px;
        height: 30px;
        line-height: 29px;
        padding: 0 15px;
        margin-right: 1px;
        &:hover {
            color: $title-color;
        }
        &.active {
            background-color: $primary-color;
            border-color: $primary-color;
            color: $light;
        }
    }
    li {
        &:first-child a {
            border-bottom-left-radius: $border-radius;
            border-top-left-radius: $border-radius;
        }
        &:last-child a {
            border-bottom-right-radius: $border-radius;
            border-top-right-radius: $border-radius;
            margin-right: 0;
        }
    }
}

.pagination-3 {
    border-bottom: 1px solid $border-color;
    li {
        a {
            display: block;
            color: $text-color;
            padding-left: 15px;
            padding-right: 15px;
            height: 37px;
            line-height: 37px;
            position: relative;
            &::before {
                background-color: $dark;
                bottom: -1px;
                content: "";
                height: 2px;
                left: 0;
                position: absolute;
                width: 0;
                @include transition( all 0.3s ease 0s);
            }
        }
    }
    .active a:before {
        width: 100%;
    }
}

.pagination-5 {
    li {
        display: inline-block;
        a {
            border: 1px solid transparent;
            color: $text-color;
            display: block;
            font-size: 12px;
            padding: 3px 15px;
        }
    }
    .active a {
        color: $title-color;
        border-color: $title-color;
    }
    &.border-top {
        border-top: 1px solid $border-color;
    }
}

.pagination-sm > li > a,
.pagination-sm > li > span,
.pagination-lg > li > a,
.pagination-lg > li > span {
    height: auto;
}

.pagination-lg > li > a,
.pagination-lg > li > span {
    font-size: 16px;
}


.chart-item {
    position: relative;
    .percent {
        background-color: $gray-bg-3;
        border-radius: 50%;
        font-size: 24px;
        height: 80px;
        left: 50%;
        line-height: 80px;
        position: absolute;
        text-align: center;
        top: 50%;
        width: 80px;
        @include transform( translate(-50%, -50%) );
        &::after {
            content: "%";
        }
    }
    canvas {
        display: block;
        margin: 0 auto;
    }
}

/*==============================================
    Range Slider
===============================================*/

.irs-line {
    overflow: visible;
    &::before {
        background-color: rgba(#000, 0.05);
        border-radius: 7px;
        bottom: -3px;
        content: "";
        left: -3px;
        position: absolute;
        right: -3px;
        top: -3px;
    }
}

.irs-min,
.irs-max {
    color: $text-color;
    top: -6px;
}

.irs-min,
.irs-max,
.irs-from,
.irs-to,
.irs-single {
    background-color: transparent;
    font-family: $roboto-slab;
    font-size: 14px;
    font-weight: 300;
}

.irs-single,
.irs-from,
.irs-to {
    color: $title-color;
    top: -6px;
}

.mtx-rs-style-3 {
    .irs-single {
        background: transparent url("../img/icons/polygon.png") no-repeat scroll center center;
        color: #fff;
        height: 30px;
        line-height: 28px;
        margin-bottom: 20px;
        text-align: center;
        top: -18px;
        width: 25px;
    }
}

.mtx-rs-style-4 {
    .irs-from,
    .irs-to {
        background: transparent url("../img/icons/polygon-2.png") no-repeat scroll center center;
        color: #fff;
        height: 30px;
        line-height: 28px;
        margin-bottom: 20px;
        text-align: center;
        top: -18px;
        width: 25px;
    }
}

/*==============================================
    Star Ratings
===============================================*/
.rating[type="number"] {
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;    
    }
}

.rating-container {
    .label {
        font-weight: 400;
        padding: 0.32em 0.7em 0.24em;
    }
    .label-success {
        background-color: $primary-color;
    }
    .filled-stars {
        color: $primary-color;
    }
}

/*==============================================
    Block Quotes
===============================================*/
.bquotes {
    border-left: 3px solid $primary-color;
    border-radius: 3px 0 0 3px;
    font-size: 14px;
    font-weight: 300;
    padding: 33px 40px 40px 37px;
    &.dark {
        border-left-color: $title-color;
    }
    &.align-right {
        border-right: 3px solid $primary-color;
        border-radius: 0 3px 3px 0;
        border-left: 0;
        text-align: right;
        &.dark {
            border-right-color: $title-color;
        }
    }
}

/*==============================================
    Selectize
===============================================*/

.selectize-control {
    &.single {
        .selectize-input {
            &.input-active {
                display: block;
            }
        }
    }
}

.selectize-input {
    display: block;
    input {
        height: auto;
    }
}

/*==============================================
    Alerts
===============================================*/
.alert {
    border: 0;
    .close {
        font-weight: 400;
        opacity: 0.5;
        @include transition( all 0.2s linear 0s );
        &:hover {
            opacity: 1;
        }
    }
    &.style-2 {
        background-color: $gray-bg-3;
        border-left: 3px solid;
    }
    &.style-3 {
        background-color: $light;
        border-radius: 0;
        border-bottom: 2px solid;
    }
    &.style-4 {
        border-left: 3px solid;
    }
}

.alert-success {
    .close {
        color: #3c763d;
    }
}

.alert-info {
    .close {
        color: #31708f;
    }
}

.alert-warning {
    .close {
        color: #8a6d3b;
    }
}

.alert-danger {
    .close {
        color: #a94442;
    }
}

/*==============================================
    Thumbnails
===============================================*/

.mt-thumbnail {
    position: relative;
    outline: 10px solid transparent;
    outline-offset: -10px;
    &:hover {
        outline-color: $light;
    }
}

.thumbnail-gallery-nav {
    margin-left: -6px;
    margin-right: -6px;
    .item {
        opacity: 0.5;
        margin-right: 6px;
        margin-left: 6px;
    }
    .slick-center {
        opacity: 1;
    }
}

span.twitter-typeahead {
    display: block !important;
}

.tt-menu {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    margin: 4px 0;
    padding: 8px 0;
    width: 100%;
}

.tt-suggestion {
    font-size: 15px;
    line-height: 24px;
    padding: 3px 20px;
    &.tt-cursor {
        background-color: $primary-color;
        color: #fff;
    }
    &:hover {
        background-color: $primary-color;
        color: #fff;
        cursor: pointer;
    }
}

/*==============================================
    Switch & radio
===============================================*/

.switch-group {
    .bootstrap-switch {
        + .bootstrap-switch {
            margin-left: 20px;
        }
    }
}

.bootstrap-switch {
    border: 0 none;
    font-family: $roboto-slab;
    font-weight: 300;
    .bootstrap-switch-handle-on,
    .bootstrap-switch-handle-off,
    .bootstrap-switch-label {
        font-size: 14px;
        height: 50px;
        line-height: 0;
        padding: 0 28px;
        width: auto !important;
        @include transition( all 0.2s linear 0s );
        &.bootstrap-switch-primary,
        &.bootstrap-switch-primary {
            background-color: $primary-color;
        }
    }
    .bootstrap-switch-label {
        border: 1px solid $border-color;
    }

    &.bootstrap-switch-on {
        &.style-2 {
            .bootstrap-switch-label {
                border-bottom-right-radius: 80px;
                border-top-right-radius: 80px;
            }
        }
    }

    &.bootstrap-switch-off {
        &.style-2 {
            .bootstrap-switch-label {
                border-bottom-left-radius: 80px;
                border-top-left-radius: 80px;
            }
            .bootstrap-switch-handle-off {
                border-bottom-right-radius: 80px;
                border-top-right-radius: 80px;
            }
        }
    }

    &.style-2 {
        .bootstrap-switch-handle-on {
            border-bottom-left-radius: 80px;
            border-top-left-radius: 80px;
        }
    }
}

.custom-radio {
    position: relative;
    input {
        height: auto;
        opacity: 0;
        width: auto;
        &:checked + label::after {
            background-color: $dark;
            border-color: rgba( $dark, 0.38 );
        }
    }
    label {
        cursor: pointer;
        margin: 0 0 0 13px;
        position: relative;
        &::after,
        &::before {
            border: 1px solid rgba($dark,0.1);
            border-radius: 15px;
            content: "";
            height: 15px;
            left: -29px;
            position: absolute;
            top: 50%;
            width: 15px;
            @include transform(translateY(-50%));
            @include transition(all 0.3s ease 0s);
        }
    }
    &.right {
        input {
            float: right;
        }
        label::before,
        label::after {
            left: auto;
            right: -32px;
        }
        label::after {
            right: -30.5px;
        }
    }
    &.light {
        label::after,
        label::before {
            border: 1px solid $light;
        }
        input:checked + label::after {
            background-color: $light;
            border-color: rgba( $light, 0.38 );
        }
    }
}

/*==============================================
    Table 1
===============================================*/

.table-1 {
    thead {
        border-bottom: 0;
    }
    tbody tr td {
        border-bottom: 1px solid $border-color;
    }
}

/*==============================================
    Switch & radio
===============================================*/






