/* =================================== */
/*  Basic Style 
/* =================================== */

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url( '../fonts/mi/MaterialIcons-Regular.eot' ); /* For IE6-8 */
    src: local('Material Icons'),
         local('../fonts/mi/MaterialIcons-Regular'),
         url( '../fonts/mi/MaterialIcons-Regular.woff2') format('woff2'),
         url( '../fonts/mi/MaterialIcons-Regular.woff') format('woff'),
         url( '../fonts/mi/MaterialIcons-Regular.ttf') format('truetype');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    // line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
}

::-moz-selection { /* Code for Firefox */
    background-color: $primary-color;
    color: $light;
}
::selection {
    background-color: $primary-color;
    color: $light;
}

body {
    color: $text-color;
    font-family: $font-stack;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

ol, ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

figure {
    margin: 0;
}

a {
	@include transition( all .2s linear 0s );
}

p {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
}

iframe {
    border: 0;
}

a, a:focus, a:hover,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn:active:focus,
.btn:focus {
    box-shadow: none;
    text-decoration: none;
    outline: 0;
}

a, a:hover, a:focus {
    color: $primary-color;
}

.card-panel {
    margin: 0;
    padding: 60px;
}
/**
 *  Typography
 *
 **/

h1, h2, h3,
h4, h5, h6,
.h1, .h2, .h3,
.h4, .h5, .h6 {
    font-family: $roboto-slab;
    color: $title-color;
    font-weight: 300;
    line-height: 1.5;
    margin-bottom: 0;
    margin-top: 0;
}

h1, .h1 { font-size: 55px;}
h2, .h2 { font-size: 39px;}
h3, .h3 { font-size: 27px;}
h4, .h4 { font-size: 19px;}
h5, .h5 { font-size: 16px;}
h6, .h6 { font-size: 14px;}

abbr[title] {
    font-style: italic;
    a {
        color: $primary-color;
    }
}

/**
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    contenteditable attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that are clearfixed.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
.clear {
    &::before,
    &::after {
        content: " ";
        display: table;
    }
    &::after {
        clear: both;
    }
}

/**
 *  Reset input fields opacity
 *
 **/

::-webkit-input-placeholder {
   opacity: 1;
   color: inherit;
}

:-moz-placeholder { /* Firefox 18- */
   opacity: 1;
   color: inherit;
}

::-moz-placeholder {  /* Firefox 19+ */
   opacity: 1;
   color: inherit;
}

:-ms-input-placeholder {  
   opacity: 1;
   color: inherit;
}

.fz-11       {font-size: 11px;}
.fz-12       {font-size: 12px;}
.fz-13       {font-size: 13px;}
.fz-14       {font-size: 14px;}
.fz-15       {font-size: 15px;}
.fz-16       {font-size: 16px;}
.fz-18       {font-size: 18px;}
.fz-20       {font-size: 20px;}
.fz-24       {font-size: 24px;}
.fz-30       {font-size: 30px;}
.fw100       {font-weight: 100;}
.fw300       {font-weight: 300;}
.fw400       {font-weight: 400;}
.fw500       {font-weight: 500;}
.f700        {font-weight: 700;}
.fsi         {font-style: italic;}
// margin top
.mt-10       {margin-top: 10px;}
.mt-15       {margin-top: 15px;}
.mt-20       {margin-top: 20px;}
.mt-25       {margin-top: 25px;}
.mt-30       {margin-top: 30px;}
.mt-35       {margin-top: 35px;}
.mt-40       {margin-top: 40px;}
.mt-50       {margin-top: 50px;}
.mt-60       {margin-top: 60px;}
.mt-80       {margin-top: 80px;}
.mt-100       {margin-top: 100px;}
// margin-left
.ml-0        {margin-left: 0 !important; }
.ml-5        {margin-left: 5px;}
.ml-10       {margin-left: 10px;}
.ml-15       {margin-left: 15px;}
.ml-20       {margin-left: 20px;}
.ml-30       {margin-left: 30px;}
.ml-50       {margin-left: 50px;}
.ml-100       {margin-left: 100px;}
// margin-right
.mr-0        {margin-right: 0 !important; }
.mr-5        {margin-right: 5px;}
.mr-15       {margin-right: 15px;}
.mr-10       {margin-right: 10px;}
.mr-20       {margin-right: 20px;}
.mr-30       {margin-right: 30px;}
.mr-50       {margin-right: 50px;}
// margin-bottom
.mb-0        {margin-bottom: 0px;}
.mb-0-i      {margin-bottom: 0px !important;}
.mb-2        {margin-bottom: 2px;}
.mb-5        {margin-bottom: 5px;}
.mb-10       {margin-bottom: 10px;}
.mb-15       {margin-bottom: 15px;}
.mb-20       {margin-bottom: 20px;}
.mb-25       {margin-bottom: 25px;}
.mb-30       {margin-bottom: 30px;}
.mb-40       {margin-bottom: 40px;}
.mb-50       {margin-bottom: 50px;}
.mb-60       {margin-bottom: 60px;}
.mb-70       {margin-bottom: 70px;}
.mb-80       {margin-bottom: 80px;}
.mb-90       {margin-bottom: 90px;}
.mb-100      {margin-bottom: 100px;}
// padding-top
.pt-0        {padding-top: 0px;}
.pt-10       {padding-top: 10px;}
.pt-15       {padding-top: 15px;}
.pt-20       {padding-top: 20px;}
.pt-25       {padding-top: 25px;}
.pt-30       {padding-top: 30px;}
.pt-40       {padding-top: 40px;}
.pt-50       {padding-top: 50px;}
.pt-60       {padding-top: 60px;}
.pt-70       {padding-top: 70px;}
.pt-80       {padding-top: 80px;}
.pt-90       {padding-top: 90px;}
.pt-100      {padding-top: 100px;}
// padding-bottom
.pb-0        {padding-bottom: 0px;}
.pb-10       {padding-bottom: 10px;}
.pb-15       {padding-bottom: 15px;}
.pb-20       {padding-bottom: 20px;}
.pb-25       {padding-bottom: 25px;}
.pb-30       {padding-bottom: 30px;}
.pb-40       {padding-bottom: 40px;}
.pb-50       {padding-bottom: 50px;}
.pb-60       {padding-bottom: 60px;}
.pb-70       {padding-bottom: 70px;}
.pb-80       {padding-bottom: 80px;}
.pb-90       {padding-bottom: 90px;}
.pb-100      {padding-bottom: 100px;}

// padding
.p-40 {padding: 40px;}

// floating
.float-left {
    float: left;
}
.float-right {
    float: right;
}


.text-italic { font-style: italic; }
.transition  { @include transition();}
.highlight   { color: $primary-color;}

.lead {
    font-size: 18px;
    line-height: 30px;
}

.lead-half {
    font-size: 16px;
    line-height: 26px;
}

.btn {
    background-color: $primary-color;
    border: 0 none;
    border-radius: $border-radius;
    line-height: 36px;
    padding: 0 15px;
    text-transform: none;
    &:hover,
    &:focus {
        background-color: $primary-color;
        color: $light;
    }
    &.uppercase,
    &.text-uppercase {
        text-transform: uppercase;
    }
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:active:focus,
.btn-primary:focus,
.btn-large,
.btn-large:hover,
.btn-large:focus {
    background-color: $primary-color;
    color: $light;
}

.no-border {border: 0 none;}
.no-radius { 
    border-radius: 0;
    &:hover {
        background-color: transparent;
        box-shadow: none;
    }
}

.btn-no-shadow {
    box-shadow: none;
}

.btn-group {
    a {
        margin-bottom: 30px;
        &:not(:last-child) {
            margin-right: 28px;
        }
    }
}

.btn-large {
    i {
        font-size: 16px;
        margin-right: 5px;
    }
}

.btn-large, .btn-flat {
    font-weight: 500;
    height: 55px;
    line-height: 55px;
    padding: 0 59px;
    white-space: nowrap;
    box-shadow: $normal-shadow;
    &:hover {
        box-shadow: $hover-shadow;
    }
}

.btn-flat {
    border-radius: 0;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.btn-md {
    font-weight: 500;
    height: 46px;
    line-height: 46px;
    padding: 0 22px;
    box-shadow: $normal-shadow;
    &:hover {
        box-shadow: $hover-shadow;
    }
}

.btn-icon-left i {
    float: left;
    margin-right: 15px;
}

.btn-icon-right i {
    float: right;
    margin-left: 15px;
}

.btn-floating {
    background-color: $primary-color;
    padding: 0;
    &:hover {
        background-color: $primary-color;
    }
    i {
        font-size: 22px;
    }
}

.icon-circle {
    background-color: $primary-color;
    border-radius: 100px;
    color: $light;
    display: inline-block;
    font-size: 20px;
    height: 72px;
    line-height: 72px;
    position: relative;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    width: 72px;
    &::before {
        background-color: rgba($primary-color, 0.15);
        border-radius: 100px;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
        @include transition(all 0.3s ease 0s);
        @include transform(scale(1.6));
    }
    &:hover, &:focus {
        background-color: $light;
        box-shadow: $box-shadow-1;
        color: $primary-color;
        &::before {
            background-color: rgba($dark, 0.04);
        }
    }
}

.btn-circle {
    border: 1px solid;
    border-radius: 100px;
    color: $text-color;
    display: inline-block;
    height: 96px;
    line-height: 96px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    width: 96px;
    &:hover {
        color: $primary-color;
    }
}

.btn-gray {
    background-color: #ddd;
    color: $primary-color;
    box-shadow: none;
    &:hover, &:focus {
        background-color: $primary-color;
        color: $light;
    }
}

.btn-border {
    background-color: transparent;
    border: 1px solid $border-color;
    box-shadow: none;
    color: $title-color;
    
    &:hover, &:focus {
        background-color: #fff;
        color: #444 !important;
    }

    &.light {
        border-color: $light;
        color: $light;
    }
}

.btn-disabled {
    background-color: transparent;
    color: $text-color;
    box-shadow: none;
    cursor: default;
    &:hover, &:focus {
        background-color: #ddd;
        box-shadow: none;
        color: $text-color;
    }
}

.btn-dark {
    background-color: $dark;
    &:hover, &:focus {
        background-color: $dark;
    }
}

.btn-round {
    border-radius: 50px;
}

.btn-flat {
    &.round {
        border-radius: 50px;
    }
    &.radius {
        border-radius: 5px;
    }
    &.btn-3d {
        box-shadow: 0 -3px 0 0 rgba(#000, 0.2) inset;
        &:hover {
            box-shadow: none;
        }
    }
    &.icon-left {
        i {
            margin-right: 8px;
        }
    }
    &.icon-right {
        i {
            margin-left: 8px;
        }
    }
    &.icon-border-left {
        padding: 0 37px 0 80px;
        position: relative;
        i {
            border-right: 1px solid;
            position: absolute;
            left: 0;
            text-align: center;
            top: -1px;
            width: 45px;
        }
    }
    &.icon-border-r8 {
        padding: 0 80px 0 37px;
        position: relative;
        i {
            border-left: 1px solid;
            position: absolute;
            right: 0;
            text-align: center;
            top: -1px;
            width: 45px;
        }
    }
}

.btn-flat.btn-primary {
    border: 1px solid transparent;
    &:hover {
        background-color: transparent;
        border-color: $primary-color;
        color: $primary-color;
    }
}

.btn-default {
    background-color: #4d4d4d;
    border: 1px solid transparent;
    color: $light;
    &:hover, &:focus {
        background-color: transparent;
        border-color: #4d4d4d;
        color: #4d4d4d;
    }
}

.btn-light-dark {
    background-color: $light-dark;
    &:hover, &:focus {
        background-color: $light-dark;
    }
}

.btn-magenta {
    background-color: #e40079;
    &:hover, &:focus {
        background-color: #e40079;
    }
}

.btn-sky-blue {
    background-color: #009deb;
    &:hover, &:focus {
        background-color: #009deb;
    }
}

.btn-green {
    background-color: #00993f;
    &:hover, &:focus {
        background-color: #00993f;
    }
}

.btn-asphalt {
    background-color: #455769;
    &:hover, &:focus {
        background-color: #455769;
    }
}

.btn-info {
    background-color: #988ae9;
    border: 1px solid transparent;
    color: $light;
    &:hover, &:focus {
        background-color: transparent;
        border-color: #988ae9;
        color: #988ae9;
    }
}

.btn-success {
    background-color: #00c96d;
    border: 1px solid transparent;
    color: $light;
    &:hover, &:focus {
        background-color: transparent;
        border-color: #00c96d;
        color: #00c96d;
    }
}

.btn-warning {
    background-color: #fec12a;
    border: 1px solid transparent;
    color: $light;
    &:hover, &:focus {
        background-color: transparent;
        border-color: #fec12a;
        color: #fec12a;
    }
}

.btn-danger {
    background-color: #ff2b2a;
    border: 1px solid transparent;
    color: $light;
    &:hover, &:focus {
        background-color: transparent;
        border-color: #ff2b2a;
        color: #ff2b2a;
    }
}
.btn-white {
    box-shadow: $normal-shadow;
    background-color: $light;
    color: $title-color;
}
.btn-white:hover, .btn-white:focus {
    box-shadow: $hover-shadow;
    background-color: $light;
    color: $title-color;
}

.btn-white i, .btn-white:hover i {
    color: $title-color;
}

.btn-tp {
    background-color: rgba($light, 0.2);
    border: 1px solid rgba($light, 0.3);
    box-shadow: none;
    color: $light;
    &:hover, &:focus {
        background-color: rgba($light, 0.3);
        box-shadow: none;
    }
}

.btn-light-border {
    border: 1px solid rgba($light, 0.6);
    box-shadow: none;
    color: $light;
    background-color: transparent;
}

.store-badge {
    display: inline-block;
    img {
        width: 215px;
    }
}

.shadow-default {
    box-shadow: $normal-shadow;
    @include transition( all 0.2s ease 0s );
    &:hover {
        box-shadow: $hover-shadow;
    }
}

.container {
    max-width: none;
    &.fullwidth {
        width: 100%;
    }
    &.no-padding {
        padding-left: 0;
        padding-right: 0;
    }
}

.no-gutter {
    margin-left: 0;
    margin-right: 0;
    & > [class^="col"] {
        padding-left: 0;
        padding-right: 0;
    }
}

.row {
    &.no-margin {
        margin-left: 0;
        margin-right: 0;
    }
}

/*
nav {
    background-color: transparent;
    box-shadow: none;
    height: auto;
    line-height: inherit;
    width: auto;
    i {
        height: auto;
    }
    ul {
        li {
            float: none;
            a {
                color: inherit;
                font-size: inherit;
                padding: inherit;
                @include transition(all 0.3s ease 0s);
                &:hover {
                    background-color: transparent;
                }
            }
        }
    }
}*/

nav li a {
    color: $light;
}

.slick-slide {
    outline: 0 none;
}

/* Date Picker */
.picker__box {
    border: 0;
}
.picker__weekday-display {
    background-color: darken($primary-color, 15%);
    font-size: 14px;
    font-weight: 400;
}
.picker__date-display,
.picker__day--selected,
.picker__day--selected:hover,
.picker--focused .picker__day--selected {
    background-color: $primary-color;
}
.picker__day.picker__day--today,
.picker__close, .picker__today {
    color: $primary-color;
}
.picker__nav--prev:hover, .picker__nav--next:hover {
    background-color: lighten($primary-color, 25%);
}
.picker__header {
    margin-bottom: 0.75em;
    margin-top: 0.75em;
}
.picker__nav--prev,
.picker__nav--next {
    margin: 0.25rem 0;
}
.picker__footer {
    .btn-flat {
        height: 34px;
        line-height: 36px;
        padding: 0 14px;
        &:hover {
            background-color: rgba($dark, 0.1);
        }
    }
}

.clockpicker-display,
.clockpicker-span-am-pm {
    color: lighten($primary-color, 25%);
}

.clockpicker-canvas line {
    stroke: rgba($primary-color, 0.25);
}

.clockpicker-canvas-bg {
    fill: rgba($primary-color, 0.25);
}

.clockpicker-canvas-bearing,
.clockpicker-canvas-fg.active {
    fill: rgba($primary-color, 0.5);
}

.clockpicker-tick {
    font-size: 16px !important;
    &:hover {
        background-color: rgba($primary-color, 0.25);
    }
    &.active {
        background-color: $primary-color;
        color: $light;
    }
}

.clockpicker-am-pm-block {
    .clockpicker-button:hover,
    .clockpicker-button.active {
        background-color: $primary-color;
        color: $light;
    }
}

/**
 *  Helper Class
 *
 **/
.margin-b-0 { margin-bottom: 0 !important;}
.section { padding: 75px 0; }
.top-half { padding-top: 75px; }
.top-full { padding-top: 150px; }
.bottom-half { padding-bottom: 75px;}
.bottom-full { padding-bottom: 150px;}
.section-full { padding: 150px 0; }
.primary { color: $primary-color;}
.primary-bg { background-color: $primary-color; }
.title-color {color: $title-color;}
.title-hover, .title-hover:hover {color: $title-color;}
.gray-bg, .section-gray { background-color: $gray-bg;}
.white-bg { background-color: $light;}
.gray-bg-2 { background-color: $gray-bg-2;}
.gray-bg-3 { background-color: $gray-bg-3;}
.gray-bg-4 { background-color: $gray-bg-4;}
.light-bg { background-color: $light-bg;}
.light-dark-bg { background-color: $light-dark;}
.text-light { color: $text-light;}
.border-top {border-top: 1px solid $border-color}
.border-bottom {border-bottom: 1px solid $border-color}
.float-none {float: none;}
.title-light, .text-white {color: $light;}
.hover-shadow {
    @include transition( all 0.2s linear 0s);
    &:hover {
        box-shadow: $shadow-default;
    }
}
.facebook  {
    background-color: $facebook;
    &:hover, &:focus {
        color: $light;
    }
}
.twitter   {
    background-color: $twitter;
    &:hover, &:focus {
        color: $light;
    }
}
.gplus     {
    background-color: $gplus;
    &:hover, &:focus {
        color: $light;
    }
}
.linkedin  {
    background-color: $linkedin;
    &:hover, &:focus {
        color: $light;
    }
}
.dribbble  {
    background-color: $dribbble;
    &:hover, &:focus {
        color: $light;
    }
}
.pinterest {
    background-color: $pinterest;
    &:hover, &:focus {
        color: $light;
    }
}
.behance   {
    background-color: $behance;
    &:hover, &:focus {
        color: $light;
    }
}
.youtube   {
    background-color: $youtube;
    &:hover, &:focus {
        color: $light;
    }
}

.roboto { font-family: $font-stack;}
.roboto-slab {
    font-family: $roboto-slab;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.text-color { color: $text-color;}
.relative { position: relative;}

.ov-hidden { overflow: hidden;}
.display-table { display: table;}
.table-cell { display: table-cell;}
.v-top { vertical-align: top;}
.v-middle { vertical-align: middle;}
.v-bottom { vertical-align: bottom;}
.w100 { width: 100%;}
.title-ls { letter-spacing: 2.8px;}
.no-ls { letter-spacing: 0;}
.block {display: block;}
.d-none {display: none;}
.inline {display: inline-block;}
.border-radius {border-radius: $border-radius;}

.material-tooltip {
    line-height: 36px;
    padding: 0 15px;
}

.absolute-center {
    left: 50%;
    position: absolute;
    top: 50%;
    white-space: nowrap;
    @include transform (translate(-50%, -50%));
}

.link-underline:hover {
    text-decoration: underline;
}

.text-link {
    color: $text-color;
    &:hover {
        color: $primary-color;
    }
}

.title-link {
    color: $title-color;
    &:hover {
        color: $primary-color;
    }
}

.title-link-light {
    color: $light;
    &:hover {
        color: $primary-color;
    }
}

.white-link, .white-link:hover {
    color: $light;
}

.flex { @include flexbox();}
.inline-flex { @include inline-flex();}
.flex-grow { @include flex-grow(1);}
.flex-wrap { @include flex-wrap (wrap);}
.flex-left { @include justify-content(flex-start);}
.flex-middle { @include align-items(center);}
.flex-right { @include justify-content(flex-end);}
.flex-top { @include align-self(flex-start);}
.flex-center { @include justify-content(center);}
.flex-bottom { @include align-self(flex-end);}
.space-between {@include justify-content(space-between);}
.space-around {@include justify-content(space-around);}
.space-evenly {@include justify-content(space-evenly);}
.flex-cell {
    @include flexbox();
    @include flex-grow(1);
}

.parallax-bg {
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    &.light {
        background-color: rgba($light, 0.6);
    }
    &.op-3 {
        background-color: rgba($dark, 0.3);
    }
    &.op-4 {
        background-color: rgba($dark, 0.4);
    }
    &.dark, &.op-5 {
        background-color: rgba(#000, 0.5);
    }
    &.op-6 {
        background-color: rgba($dark, 0.6);
    }
    &.dark-2 {
        background-color: rgba(#303030, 0.8);
    }
    &.op-7 {
        background-color: rgba($dark, 0.7);
    }
    &.dark-3, &.dark-8, &.op-8 {
        background-color: rgba($dark, 0.8);
    }
    &.dark-9 {
        background-color: rgba($dark, 0.9);
    }
}

.ratings {
    color: #f3bc39;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &::before {
        content: "\f006  \f006  \f006  \f006  \f006";
    }
    &.star-half::before {
        content: "\f123  \f006  \f006  \f006  \f006";
    }
    &.star-1::before {
        content: "\f005  \f006  \f006  \f006  \f006";
    }
    &.star-1-half::before {
        content: "\f005  \f123  \f006  \f006  \f006";
    }
    &.star-2::before {
        content: "\f005  \f005  \f006  \f006  \f006";
    }
    &.star-2-half::before {
        content: "\f005  \f005  \f123  \f006  \f006";
    }
    &.star-3::before {
        content: "\f005  \f005  \f005  \f006  \f006";
    }
    &.star-3-half::before {
        content: "\f005  \f005  \f005  \f123  \f006";
    }
    &.star-4::before {
        content: "\f005  \f005  \f005  \f005  \f006";
    }
    &.star-4-half::before {
        content: "\f005  \f005  \f005  \f005  \f123";
    }
    &.star-5::before {
        content: "\f005  \f005  \f005  \f005  \f005";
    }
}

.slick-vertical .slick-slide {
    border: 0;
}

// caret up
.caret-up {
    clip: rect(-10px, 60px, 25px, -10px);
    left: 50%;
    opacity: 1;
    position: absolute;
    top: 0;
    @include transform (translate(-50%, -50%));
}

.caret-up::before {
    border: 1px solid #fff;
    box-sizing: border-box;
    content: "";
    display: block;
    height: 50px;
    width: 50px;
    @include transform(rotate(45deg));
}

.icon-share {
    @include transform(rotateY(180deg));
}

.v-center-content {
    &::after {
        content: "";
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }
    .vcc-inner {
        display: inline-block;
        max-width: 100%;
        vertical-align: middle;
    }
}

// indexing 
.z-index-1 {z-index: 1;}
.z-index-2 {z-index: 2;}
.z-index-3 {z-index: 3;}
.z-index-4 {z-index: 4;}
.z-index-5 {z-index: 5;}
.z-index-12 {z-index: 12;}
.z-index-13 {z-index: 13;}
.z-index-14 {z-index: 14;}
.z-index-15 {z-index: 15;}

.z-depth-1 {
    box-shadow: $normal-shadow;
}

.btn-primary {
    box-shadow: $normal-shadow;
    &:hover {
        box-shadow: $hover-shadow;
    }
}









