@import 'variables';
@import 'mixins';

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
    color: $title-color;
}


/* 
====== Move-horizontal effect ======
*/
.mfp-move-horizontal {
    /* start state */
    .mfp-with-anim {
        opacity: 0;
        @include transition(all 0.3s);
        @include transform(translateX(-50px));
    }
    &.mfp-bg {
        opacity: 0;
        @include transition(all 0.3s);
    }

    /* animate in */
    &.mfp-ready {
        .mfp-with-anim {
            opacity: 1;
            @include transform(translateX(0));
        }
        &.mfp-bg {
            opacity: 0.8;
        }
    }

    /* animate out */
    &.mfp-removing {
        .mfp-with-anim {
            @include transform(translateX(50px));
            opacity: 0;
        }
        &.mfp-bg {
            opacity: 0;
        }
    }
}

/*
====== Zoom effect ======
*/
.mfp-zoom-in {
    /* start state */
    .mfp-with-anim {
        opacity: 0;
        @include transition(all 0.2s ease-in-out);
        @include transform(scale(0.8));
    }
    &.mfp-bg {
        opacity: 0;
        @include transition(all 0.3s ease-out);
    }
    /* animate in */
    &.mfp-ready {
        .mfp-with-anim {
            opacity: 1;
            @include transform(scale(1));
        }
        &.mfp-bg {
            opacity: 0.8;
        }
    }
    /* animate out */
    &.mfp-removing {
        .mfp-with-anim {
            @include transform(scale(0.8));
            opacity: 0;
        }
        &.mfp-bg {
            opacity: 0;
        }
    }
}

/*
====== Team Single BG ======
*/
.mfp-bg {
    background-color: #fff;
    opacity: 1;
    &.dark {
        background-color: #000;
        opacity: 0.9;
    }
}

.dark {
    .mfp-close {
        color: $light;
    }
}




