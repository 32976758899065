/*==============================================
    Services
===============================================*/
.service {
    &.type-1 {
        .icon {
            background-color: $light;
            border-radius: 50%;
            box-shadow: 2px 3px 4px 0 rgba(#000, 0.16), 0 0 3px 0 rgba(#000, 0.12);
            font-size: 30px;
            height: 120px;
            margin: 0 auto 20px;
            text-align: center;
            width: 120px;
            @include transition(all 0.3s ease 0s);
            i {
                line-height: 120px;
            }
        }
        p {
            font-size: 13px;
            font-weight: 300;
            margin: 20px 0 0;
        }
        &:hover {
            .icon {
                box-shadow: $hover-shadow;
                color: $primary-color;
            }
        }
    }
    &.style2 {
        margin-bottom: 30px;
        .icon {
            box-shadow: $normal-shadow;
            border-radius: 50%;
            height: 100px;
            margin: 0 auto 45px;
            width: 100px;
            i {
                font-size: 30px;
                line-height: 100px;
            }
        }
        h3 {
            font-size: 24px;
            letter-spacing: .7px;
            margin-bottom: 26px;
            text-transform: uppercase;
        }
    }
    &.style3 {
        background-color: $light;
        padding: 50px 35px 35px;
        @include transition(all 0.3s ease 0s);
        .icon {
            color: $primary-color;
            display: block;
            margin-bottom: 40px;
            i {
                font-size: 35px;
            }
        }
        h3 {
            font-size: 24px;
            line-height: 33px;
            margin-bottom: 16px;
        }
        p {
            color: #a3a3a3;
            font-size: 16px;
            line-height: 24px;
        }
        a {
            float: right;
            margin-top: 54px;
        }
        &:hover {
            box-shadow: 0 5px 10px rgba($dark, 0.15);
        }
    }
    &.style4 {
        span {
            background-color: $light;
            margin-bottom: 20px;
            i {color: $text-color;}
        }
        h3 {
            font-size: 24px;
            margin-bottom: 20px;
        }
        &:hover {
            span {
                &:hover {
                    background-color: $primary-color;
                    i {color: $light;}
                }
            }
        }
    }
    &.style5 {
        margin-bottom: 30px;
        .btn-large {
            margin-bottom: 22px;
        }
        h3 {
            font-size: 24px;
            margin-bottom: 17px;
        }
        &:hover {
            .btn-large {
                background-color: $light;
                i {color: $primary-color;}
            }
        }
    }
    &.style6 {
        background-color: $light;
        h4 {
            background-color: $primary-color;
            color: $light;
            font-size: 20px;
            padding: 25px 30px;
        }
        p {
            padding: 25px 30px;
        }
    }
    &.style7 {
        padding: 57px 70px 53px;
        @include transition(box-shadow 0.4s ease 0s);
        .icon {
            font-size: 60px;
            margin-bottom: 22px;
            @include transition(color 0.4s ease 0s);
        }
        h4 {
            font-size: 24px;
            margin-bottom: 22px;
        }
        a {
            color: $title-color;
            &:hover {
                color: $primary-color;
            }
        }
        &:hover {
            box-shadow: $box-shadow-1;
            .icon, a {
                color: $primary-color;
            }
        }
    }
    &.style8 {
        .icon {
            color: $title-color;
            font-size: 48px;
            margin-bottom: 30px;
            @include transition(color 0.4s ease 0s);
        }
        h4 {
            font-size: 24px;
            letter-spacing: 1.4px;
            margin-bottom: 16px;
            @include transition(color 0.4s ease 0s);
        }
        &:hover {
            .icon, h4 {
                color: $primary-color;
            }
        }
    }
    &.style9,
    &.style14 {
        .icon {
            color: $title-color;
            font-size: 60px;
            margin-bottom: 26px;
            @include transition(color 0.4s ease 0s);
        }
        h4 {
            font-size: 14px;
            letter-spacing: 1.4px;
            margin-bottom: 39px;
            letter-spacing: 2.8px;
            @include transition(color 0.4s ease 0s);
        }
        .sep {
            background-color: $title-color;
            display: block;
            height: 2px;
            margin: -24px auto 22px;
            width: 30px;
            @include transition(all 0.3s ease 0s);
            &.left {
                margin-left: 0;
            }
        }
        &:hover {
            .icon, h4 {
                color: $primary-color;
            }
        }
    }
    &.style9 {
        &.shadow {
            padding: 60px;
            @include transition(all 0.3s ease 0s);
        }
        &:hover {
            //box-shadow: $box-shadow-2;
            h4 {
                color: $title-color;
            }
            .sep {
                background-color: $primary-color;
            }
        }
    }
    &.style10 {
        margin-bottom: 75px;
        .icon {
            color: $title-color;
            font-size: 60px;
            margin-bottom: 26px;
        }
        h4 {
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 39px;
            position: relative;
            &::after {
                content: "";
                background-color: $title-color;
                height: 2px;
                width: 30px;
                margin: 0 auto;
            }
        }
        .sep {
            background-color: $title-color;
            display: block;
            height: 2px;
            margin: -24px auto 22px;
            width: 30px;
            &.left {
                margin-left: 0;
            }
        }
        &.light {
            color: $light;
            .icon, h4 {
                color: $light;
            }
            .sep {
                background-color: $light;
            }
        }
    }
    &.style11 {
        border: 1px solid $border-color;
        padding: 60px 45px;
        @include transition(border-color 0.3s ease 0s);
        .icon {
            color: $title-color;
            font-size: 60px;
            margin-bottom: 30px;
        }
        h4 {
            margin-bottom: 33px;
        }
        .more {
            bottom: 0;
            height: 80px;
            opacity: 0;
            position: absolute;
            right: 0;
            width: 80px;
            &::after {
                border-color: transparent $primary-color $primary-color transparent;
                border-style: solid;
                border-width: 40px;
                content: "";
                position: absolute;
                right: 0;
                top: 0;
            }
            span {
                background: transparent url('../img/icons/plus-1.png') center bottom no-repeat;
                position: absolute;
                height: 20px;
                width: 20px;
                right: 15px;
                bottom: 15px;
                z-index: 1;
            }
        }
        &:hover {
            border-color: $primary-color;
            .more {
                opacity: 1;
            }
        }
    }
    &.style12 {
        h2 {
            font-size: 22px;
            margin-bottom: 15px;
        }
        .icon {
            display: inline-block;
            font-size: 40px;
            margin-left: 25px;
        }
    }
    &.style13 {
        border: 1px solid $border-color;
        padding: 100px 80px 70px;
        @include transition(border-color 0.3s ease 0s);
        .icon {
            background-color: $light;
            font-size: 60px;
            left: 65px;
            padding: 0 16px;
            position: absolute;
            top: 0;
            z-index: 1;
            @include transition(color 0.3s ease 0s);
            @include transform(translateY(-50%));
        }
        h4 {
            margin-bottom: 35px;
        }
        &:hover {
            border-color: $primary-color;
            .icon {
                color: $primary-color;
            }
        }
    }
    &.style14 {
        .icon {
            background: url("../img/services/1.jpg") repeat scroll center center;
            border-radius: 50%;
            height: 250px;
            line-height: 250px;
            margin: 0 auto 40px;
            text-align: center;
            position: relative;
            width: 250px;
            z-index: 0;
            @include transition(all 0.3s ease 0s);
            &::after {
                background-color: $gray-bg-3;
                border-radius: 50%;
                bottom: -1px;
                content: "";
                left: 0;
                opacity: 1;
                position: absolute;
                right: -1px;
                top: -1px;
                z-index: -1;
                @include transition(all 0.3s ease 0s);
            }
        }
        &:hover {
            .icon {
                &::after {
                    background-color: rgba($light, 0.75);
                }
            }
        }
    }
    &.style15 {
        background-color: $gray-bg-4;
        padding: 53px 40px;
        @include transition(box-shadow 0.3s ease 0s);
        @extend .relative;
        &::before,
        &::after {
            background-color: $primary-color;
            bottom: 0;
            content: "";
            height: 0;
            position: absolute;
            right: 0;
            width: 2px;
            z-index: 1;
            @include transition(all 0.3s ease 0s);
        }
        &::before {
            height: 2px;
            width: 0;
        }
        img {
            margin-bottom: 38px;
        }
        h6 {
            margin-bottom: 15px;
            @include transition(color 0.3s ease 0s);
        }
        &:hover {
            box-shadow: $box-shadow-2;
            &::before {
                width: 80px;
            }
            &::after {
                height: 80px;
            }
            h6 {
                color: $primary-color;
            }
        }
    }
    &.style16 {
        img {
            margin-bottom: 24px;
        }
        h6 {
            font-weight: 400;
            margin-bottom: 20px;
        }
        .more {
            background-color: #00b8d3;
            border-radius: 0 30px 30px 0;
            color: #fff;
            display: block;
            height: 35px;
            position: relative;
            width: 80px;
            @include transition (all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0s);
        }
        .text {
            position: absolute;
            top: 50%;
            opacity: 0;
            white-space: nowrap;
            @include transition ();
            @include calc (left, "50% - 17px");
            @include transform (translate(-50%, -50%));
        }
        .arrow {
            background-color: #fff;
            border-radius: 30px;
            color: #00b8d3;
            font-size: 18px;
            height: 33px;
            line-height: 33px;
            position: absolute;
            right: 1px;
            text-align: center;
            top: 1px;
            width: 33px;
        }
        &:nth-child(2) {
            .more {
                background-color: #ff1644;
            }
            .arrow {
                color: #ff1644;
            }
        }
        &:nth-child(3) {
            .more {
                background-color: #00c752;
            }
            .arrow {
                color: #00c752;
            }
        }
        &:nth-child(4) {
            .more {
                background-color: #ffd500;
            }
            .arrow {
                color: #ffd500;
            }
        }
        &:nth-child(5) {
            .more {
                background-color: #ff0000;
            }
            .arrow {
                color: #ff0000;
            }
        }
        &:nth-child(6) {
            .more {
                background-color: #efefef;
            }
            .arrow {
                color: #efefef;
            }
        }
        &:nth-child(7) {
            .more {
                background-color: #f5ba8f;
            }
            .arrow {
                color: #f5ba8f;
            }
        }
        &:nth-child(5) {
            .more {
                background-color: #00b8ed;
            }
            .arrow {
                color: #00b8ed;
            }
        }
        &:hover {
            .more {
                width: 250px;
            }
            .text {
                opacity: 1;
            }
        }
    }
    &.style17 {
        .text {
            padding: 45px 40px 42px;
            position: relative;
            &::before {
                background-color: red;
                bottom: 0;
                content: "";
                height: 1px;
                left: 0;
                position: absolute;
                width: 0;
                @include transition();
            }
        }
        h4 {
            font-size: 24px;
            margin-bottom: 20px;
        }
        .more {
            font-weight: 500;
            margin-top: 10px;
            i {margin-left: 5px;}
        }
        &:hover {
            .text::before {
                width: 100%;
            }
        }
    }
    &.style18 {
        .icon {
            border: 1px solid $border-color;
            border-radius: 3px;
            display: inline-block;
            font-size: 26px;
            height: 80px;
            line-height: 80px;
            margin-right: 30px;
            min-width: 80px;
            text-align: center;
            width: 80px;
        }
        h3 {
            font-size: 24px;
            margin: -8px 0 12px;
        }
        &:hover {
            .icon {
                border-color: $primary-color;
                color: $primary-color;
            }
            h3 {
                color: $primary-color;
            }
        }
    }
    &.style19 {
        box-shadow: $normal-shadow;
        background-color: $light;
        .icon {
            font-size: 48px;
            padding: 70px 0;
        }
        h4 {
            margin-top: 14px;
        }
        .text {
            background-color: rgba($light, 0.95);
            bottom: 0;
            height: 0;
            padding: 0 30px;
            position: absolute;
            right: 0;
            h4 {
                margin-bottom: 22px;
            }
        }
        &:hover {
            .text {
                height: 100%;
            }
        }
    }

    &.style20 {
        background-color: $light;
        border-radius: 3px;
        padding: 100px 70px 95px;
        .icon, h3 {
            font-size: 24px;
        }
        &:hover {
            box-shadow: $shadow-default;
            .icon, h3 {
                color: $primary-color;
            }
        }
    }

    &.style21 {
        img {
            width: 100%;
        }
        &:hover {
            box-shadow: $shadow-default;
            .gray-bg-3 {
                background-color: $light;
            }
            h5, a {
                color: $primary-color;
            }
        }
    }

    &.style22 {
        border-radius: $border-radius;
        padding: 80px 40px 72px;
        .icon {
            font-size: 40px;
        }
        &:hover {
            background-color: $light;
            box-shadow: $shadow-default;
            .icon, a {
                color: $primary-color;
            }
        }
    }

    &.style23 {
        .icon {
            font-size: 60px;
            line-height: 300px;
            min-height: 300px;
        }
        .desc {
            padding: 0 30px;
        }
        h3 {
            font-size: 24px;
        }
        &:hover {
            .icon {
                background-color: $light;
                box-shadow: $shadow-default;
                color: $primary-color;
            }
            a {
                color: $primary-color;
            }
        }
    }

    &.style-24 {

    }
}

.bg-75-parcent {
    height: 75%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}