
/*==============================================
    Progress Bars
===============================================*/
.progress-item {
    &.type-1, &.style2, &.style3 {
        margin-bottom: 32px;
        &:last-child {
            margin-bottom: 0;
        }
        p {
            color: $title-color;
            margin: 0 0 12px;
        }
        .progress {
            background-color: #d6d6d6;
            border-radius: 0;
            box-shadow: none;
            height: 20px;
            overflow: visible;
            margin-bottom: 0;
        }
        .progress-bar {
            background-color: $primary-color;
            box-shadow: none;
            position: relative;
        }
        .value {
            color: $light;
            height: 40px;
            line-height: 40px;
            position: absolute;
            right: 0;
            text-align: center;
            top: -15px;
            width: 40px;
            @include transform(translate(50%, -100%));
            &:before, &:after {
                border-radius: 50% 50% 0;
                bottom: 0;
                content: "";
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
                @include transform(rotate(45deg));
            }
            &:before {
                background-color: #757575;
                z-index: -1;
            }
            &:after {
                border: 2px solid $light;
                box-shadow: $box-shadow-1;
                bottom: 0;
            }
        }
    }
    &.style2 {
        .progress {
            background-color: transparent;
            border: 1px solid $border-color;
            border-radius: 10px;
            height: 10px;
        }
        .progress-bar {
            border-radius: 10px;
            height: 4px;
            left: 2px;
            top: 2px;
        }
    }
    &.style3 {
        .progress {
            background-color: #d6d6d6;
            height: 8px;
        }
        .progress-bar {
            height: 4px;
            margin-top: 2px;
        }
        .value {
            &::before {
                background-color: $primary-color;  
            }
            &::after {
                content: none;
            }
        }
    }
    &.style4,
    &.style5 {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
        p {
            color: $title-color;
            font-size: 18px;
            margin-bottom: 9px;
        }
        .progress {
            background-color: $gray-bg;
            border-radius: 10px;
            box-shadow: none;
            height: 10px;
            margin: 0;
            overflow: visible;
        }
        .progress-bar {
            background-color: $primary-color;
            border-radius: 10px;
            box-shadow: none;
            position: relative;
        }
        .value {
            color: $title-color;
            font-size: 18px;
            position: absolute;
            right: 0;
            top: -32px;
        }
    }
    &.style5,
    &.style6 {
        margin-bottom: 24px;
        .progress {
            height: 4px;
        }
        .value {
            top: 12px;
        }
        p {
            font-family: $roboto-slab;
            font-size: 14px;
            letter-spacing: 2.8px;
            margin: 4px 0 0;
            text-transform: uppercase;
        }
    }
    &.style6 {
        margin-bottom: 33px;
        &:last-child {
            margin-bottom: 0;
        }
        .progress {
            background-color: $primary-color;
            margin: 0;
            overflow: visible;
        }
        .progress-bar {
            background-color: $dark;
        }
        .value {
            color: $title-color;
            font-size: 14px;
            letter-spacing: 2.8px;
            position: absolute;
            right: 0;
        }
        p {
            color: $title-color;
        }
    }
}
