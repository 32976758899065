
/*==============================================
    Subscription
===============================================*/
.subscribe-block-1 {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 10;
    .left-bg {
        background-color: $primary-color;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
    }
    .subscribe-inner {
        background-color: $primary-color;
        color: $light;
        padding: 50px 140px 50px 0;
        p {
            font-size: 16px;
            margin: 0;
        }
        h3 {
            color: $light;
            font-size: 24px;
        }
        .subscribe {
            background-color: #fff;
            border-radius: 4px;
            margin-top: 20px;
            position: relative;
            @include flexbox();
            @include align-items(center);
            label {
                font-size: 24px;
                margin: 0;
                padding: 0 21px 0 30px;
                text-align: center;
            }
            input {
                border: 0 none;
                box-shadow: none;
                color: $text-color;
                font-size: 16px;
                height: 60px;
                margin: 0;
            }
            .mail-sub {
                background-color: $primary-color;
                border: 0 none;
                border-radius: 4px;
                font-size: 30px;
                height: 56px;
                line-height: 56px;
                padding: 0 32px;
                position: relative;
                right: 2px;
            }
        }
    }
}

.newsletter-1 {
    border: 1px solid $border-color;
    border-radius: 5px;
    margin: 40px 0 0 auto;
    max-width: 440px;
    position: relative;
    width: 100%;
    .nl-1-input {
        border: 0 none;
        height: 50px;
        margin: 0;
        padding: 0 15px 0 30px;
        width: 100%;
    }
    .nl-1-submit {
        background-color: $primary-color;
        border: 0 none;
        border-radius: 0 5px 5px 0;
        color: $light;
        font-weight: 500;
        height: 46px;
        padding: 0 36px;
        position: relative;
        right: 2px;
        top: 2px;
    }
}

.newsletter-2 {
    .nl-2-input {
        background-color: $gray-bg-3;
        border: 0;
        border-radius: 50px 0 0 50px;
        height: 70px;
        margin: 0;
        padding: 0 20px 0 40px;
    }
    .nl-2-submit {
        background-color: $primary-color;
        border: 0 none;
        border-radius: 0 50px 50px 0;
        color: $light;
        font-weight: 500;
        padding: 0 75px;
    }
    p {
        font-size: 18px;
    }
}

.newsletter-3 {
    .nl-input {
        background-color: transparent;
        border: 1px solid rgba($light, 0.7);
        border-radius: 50px;
        height: 68px;
        margin: 0;
        padding: 0 80px 0 60px;
        width: 100%;
    }
    .nl-submit {
        background-color: $primary-color;
        border: 0 none;
        border-radius: 50px;
        color: $light;
        font-weight: 500;
        margin-left: -60px;
        padding: 0 90px;
        white-space: nowrap;
    }
    &.dark-border {
        .nl-inner {
            border: 1px solid $border-color;
            border-radius: 50px;
            padding: 1px;
        }
    }
    p {
        color: $light;
        font-size: 16px;
    }

    &.dark {
        .nl-input {
            border: 1px solid $border-color;
            color: $text-color;
        }
        p {
            color: $text-color;
        }
    }

    &.light {
        .nl-inner {
            border: 1px solid rgba($light, 0.7);
            border-radius: 50px;
            padding: 1px;
        }
        .nl-input {
            border: 0;
        }
        .nl-submit {
            background-color: $light;
            color: $title-color;
        }
    }
}

.newsletter-4 {
    border-radius: 30px;
    .nl-input {
        background-color: transparent;
        border: 0 none;
        border-radius: 30px 0 0 30px;
        height: 40px;
        margin-bottom: 0;
        padding: 0 0 0 30px;
        width: 100%;
    }
    .nl-submit {
        border: 0 none;
        border-radius: 30px;
        color: #fff;
        margin: 2px;
        padding: 0 40px;
    }
}

.section-newsletter {
    .nl-logo {
        left: 50%;
        position: absolute;
        top: 80px;
        @include transform (translateX(-50%));
    }
    .nl-input {
        height: 50px;
    }
}




