/*!
 * bootstrap-fileinput v4.3.8
 * http://plugins.krajee.com/file-input
 *
 * Author: Kartik Visweswaran
 * Copyright: 2014 - 2017, Kartik Visweswaran, Krajee.com
 *
 * Licensed under the BSD 3-Clause
 * https://github.com/kartik-v/bootstrap-fileinput/blob/master/LICENSE.md
 */
.file-loading {
    top: 0;
    right: 0;
    width: 25px;
    height: 25px;
    font-size: 999px;
    text-align: right;
    color: #fff;
    background: transparent url('../img/loading.gif') top left no-repeat;
    border: none;
}

.file-object {
    margin: 0 0 -5px 0;
    padding: 0;
}

.btn-file {
    position: relative;
    overflow: hidden;

    input[type=file] {
        position: absolute;
        top: 0;
        right: 0;
        min-width: 100%;
        min-height: 100%;
        text-align: right;
        opacity: 0;
        background: none repeat scroll 0 0 transparent;
        cursor: inherit;
        display: block;
    }

}

.file-caption-name {
    display: inline-block;
    overflow: hidden;
    height: 20px;
    word-break: break-all;
}

.input-group-lg .file-caption-name {
    height: 25px;
}

.file-zoom-dialog {
    text-align: left;
}

.file-error-message {
    color: #a94442;
    background-color: #f2dede;
    margin: 5px;
    border: 1px solid #ebccd1;
    border-radius: 4px;
    padding: 15px;

    pre,
    ul {
        margin: 0;
        text-align: left;
    }

    pre {
        margin: 5px 0;
    }

}

.file-caption-disabled {
    background-color: #EEEEEE;
    cursor: not-allowed;
    opacity: 1;
}

.file-preview {
    border-radius: 5px;
    border: 1px solid #ddd;
    padding: 5px;
    width: 100%;
    margin-bottom: 5px;
}

.file-preview-frame {
    position: relative;
    display: table;
    margin: 8px;
    height: 160px;
    border: 1px solid #ddd;
    box-shadow: 1px 1px 5px 0 #a2958a;
    padding: 6px;
    float: left;
    text-align: center;
    vertical-align: middle;

    &:not(.file-preview-error):hover {
        box-shadow: 3px 3px 5px 0 #333;
    }

}

.file-preview-image {
    vertical-align: middle;
    image-orientation: from-image;
}

.file-preview-text {
    display: block;
    color: #428bca;
    border: 1px solid #ddd;
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
    outline: none;
    padding: 8px;
    resize: none;
}

.file-preview-html {
    border: 1px solid #ddd;
    padding: 8px;
    overflow: auto;
}

.file-zoom-dialog .file-preview-text {
    font-size: 1.2em;
}

.file-preview-other {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    text-align: center;
    vertical-align: middle;
    padding: 10px;

    &:hover {
        opacity: 0.8;
    }

}

.file-actions,
.file-other-error {
    text-align: left;
}

.file-other-icon {
    font-size: 4.8em;
}

/* noinspection CssOverwrittenProperties */
.file-zoom-dialog .file-other-icon {
    font-size: 8em;
    font-size: 55vmin;
}

.file-input-new {
    .file-preview,
    .close,
    .glyphicon-file,
    .fileinput-remove-button,
    .fileinput-upload-button {
        display: none;
    }

}

.file-input-ajax-new {
    .fileinput-remove-button,
    .fileinput-upload-button {
        display: none;
    }

}

.file-caption-main {
    width: 100%;
}

.file-input-ajax-new .no-browse .input-group-btn,
.file-input-new .no-browse .input-group-btn {
    display: none;
}

.file-input-ajax-new .no-browse .form-control,
.file-input-new .no-browse .form-control {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.file-thumb-loading {
    background: transparent url('../img/loading.gif') no-repeat scroll center center content-box !important;
}

.file-actions {
    margin-top: 15px;
}

.file-footer-buttons {
    float: right;
}

.file-upload-indicator {
    display: inline;
    cursor: default;
    opacity: 0.8;
    width: 60%;

    &:hover {
        font-weight: bold;
        opacity: 1;
    }

}

.file-footer-caption {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 160px;
    text-align: center;
    padding-top: 4px;
    font-size: 11px;
    color: #777;
    margin: 5px auto;
}

.file-preview-error {
    opacity: 0.65;
    box-shadow: none;
}

.file-preview-frame:not(.file-preview-error) .file-footer-caption:hover {
    color: #000;
}

.file-drop-zone {
    border: 1px dashed #aaa;
    border-radius: 4px;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    margin: 12px 15px 12px 12px;
    padding: 5px;
}

.file-drop-zone-title {
    color: #aaa;
    font-size: 1.6em;
    padding: 85px 10px;
    cursor: default;
}

.file-preview .clickable,
.clickable .file-drop-zone-title {
    cursor: pointer;
}

.file-drop-zone {
    &.clickable {
        &:hover {
            border: 2px dashed #999;
        }

        &:focus {
            border: 2px solid #5acde2;
        }

    }

    .file-preview-thumbnails {
        cursor: default;
    }

}

.file-highlighted {
    border: 2px dashed #999 !important;
    background-color: #f0f0f0;
}

.file-uploading {
    background: url('../img/loading-sm.gif') no-repeat center bottom 10px;
    opacity: 0.65;
}

.file-thumb-progress {
    height: 10px;

    .progress,
    .progress-bar {
        height: 10px;
        font-size: 9px;
        line-height: 10px;
    }

}

.file-thumbnail-footer {
    position: relative;
}

.file-thumb-progress {
    position: absolute;
    top: 35px;
    left: 0;
    right: 0;
}

.file-zoom-fullscreen {
    &.modal {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .modal-dialog {
        position: fixed;
        margin: 0;
        width: 100%;
        height: 100%;
        padding: 0;
    }

    .modal-content {
        border-radius: 0;
        box-shadow: none;
    }

    .modal-body {
        overflow-y: auto;
    }

}

.file-zoom-dialog {
    .modal-body {
        position: relative !important;
    }

    .btn-navigate {
        position: absolute;
        padding: 0;
        margin: 0;
        background: transparent;
        text-decoration: none;
        outline: none;
        opacity: 0.7;
        top: 45%;
        font-size: 4em;
        color: #1c94c4;
    }

    .floating-buttons {
        position: absolute;
        top: 5px;
        right: 10px;
    }

}

.floating-buttons {
    z-index: 3000;

    .btn {
        z-index: 3000;
    }

}

.file-zoom-dialog .kv-zoom-actions .btn,
.floating-buttons .btn {
    margin-left: 3px;
}

.file-zoom-dialog {
    .btn-navigate {
        &:not([disabled]) {
            &:hover,
            &:focus {
                outline: none;
                box-shadow: none;
                opacity: 0.5;
            }

        }

        &[disabled] {
            opacity: 0.3;
        }

    }

    .btn-prev {
        left: 1px;
    }

    .btn-next {
        right: 1px;
    }

}

.file-drag-handle {
    display: inline;
    margin-right: 2px;
    font-size: 16px;
    opacity: 0.6;
    cursor: not-allowed;
}

.file-sortable .file-drag-handle {
    cursor: move;
    cursor: -webkit-grabbing;
    opacity: 1;

    &:hover {
        opacity: 0.7;
    }

}

.file-zoom-content {
    height: 480px;
    text-align: center;
}

.file-preview-initial.sortable-chosen {
    background-color: #d9edf7;
}

.file-preview-frame.sortable-ghost {
    background-color: #eee;
}

/* IE 10 fix */
.btn-file::-ms-browse {
    width: 100%;
    height: 100%;
}