/*==============================================
    Widgets
===============================================*/
.widget-area {
    .widget:not(:last-child) {
        margin-bottom: 30px;
    }
}

.widget {
    &.type-1 {
        color: $text-light;
        h4 {
            color: $text-light;
            margin-bottom: 31px;
            text-transform: uppercase;
        }
        h5 {
            color: $text-light;
            margin-bottom: 13px;
        }
        address {
            border-bottom: 1px solid rgba($light, 0.1);
            line-height: 24px;
            padding-bottom: 10px;
            position: relative;
            &:last-child {
                border-bottom: 0 none;
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
        .widget-link {
            font-weight: 300;
            i {
                float: left;
                font-size: 20px;
                line-height: inherit;
                padding-right: 9px;
            }
            &:hover {
                text-decoration: underline;
            }
        }
    }

    &.type-2 {
        padding: 40px 37px;
        a {
            color: $text-color;
            &:hover {
                color: $title-color;
            }
        }
    }
}

.pages_list {
    li a {
        line-height: 2;
    }
}

.product-categories li {
    a {
        line-height: 30px;
    }
    ul {
        background-color: $gray-bg-3;
        display: none;
        margin: 5px -37px;
        padding: 5px 40px 5px 78px;
    }
}

.noUi-connect,
.noUi-target .range-label,
.noUi-horizontal .noUi-handle {
    background-color: $primary-color;
}

.filter-by-color {
    -webkit-column-count: 2; /* Chrome, Safari, Opera */
       -moz-column-count: 2; /* Firefox */
            column-count: 2;
    li {
        margin-bottom: 5px;
    }
    .product-radio {
        border: 0 none !important;
        height: auto;
        width: auto;
        @include flexbox();
        label {
            border: 2px solid #fff;
            box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.2);
            height: 19px;
            margin-right: 15px;
            min-width: 19px;
            position: relative;
            width: 19px;
            @include transform (scale(1));
        }
    }
}

// megazie widget

.mag-widget-title {
    background-color: $light;
    border-top: 2px solid $primary-color;
    line-height: 58px;
}

.mag-widget-title-2 {
    background-color: $gray-bg-3;
    border-left: 2px solid $primary-color;
    font-size: 16px;
    letter-spacing: 2.8px;
    margin-bottom: 10px;
    padding: 21px 30px 23px;
    text-transform: uppercase;
}

.magazine-widget {
    .widget-inner {
        background-color: $gray-bg-3;
        padding: 30px;
    }
    .search-form {
        input {
            background-color: $light;
            border: 0 none;
            border-radius: 2px;
            font-size: 12px;
            font-style: italic;
            height: 40px;
            padding: 0 20px;
        }
        button {
            background-color: $primary-color;
            border-radius: 2px;
            border: 0 none;
            color: $light;
            line-height: 40px;
            width: 100%;
        }
    }
}

.mag-categories {
    margin: -30px;
    li {
        line-height: 50px;
        position: relative;
        &::after {
            background-color: #009688;
            bottom: 0;
            content: "";
            height: 0;
            position: absolute;
            right: 0;
            transition: all 0.3s ease 0s;
            width: 3px;
        }
        &:hover::after {
            height: 100%;
        }
        &:nth-child(2n+1) {
            background-color: rgba($dark, 0.02);
        }
        .post-count {
            background-color: #009688;
            border-radius: 0 5px 5px 0;
            color: $light;
            font-family: $roboto-slab;
            font-size: 18px;
            left: 0;
            position: absolute;
            text-align: center;
            top: 0;
            width: 50px;
        }
        &:nth-child(2) {
            .post-count {
                background-color: #ba68c8;
            }
            &:hover::after {
                background-color: #ba68c8;
            }
        }
        &:nth-child(3) {
            .post-count {
                background-color: #f06292;
            }
            &:hover::after {
                background-color: #f06292;
            }
        }
        &:nth-child(4) {
            .post-count {
                background-color: #ffa726;
            }
            &:hover::after {
                background-color: #ffa726;
            }
        }
        &:nth-child(5) {
            .post-count {
                background-color: #8d6e63;
            }
            &:hover::after {
                background-color: #8d6e63;
            }
        }
        &:nth-child(6) {
            .post-count {
                background-color: #78909c;
            }
            &:hover::after {
                background-color: #78909c;
            }
        }
        &:nth-child(7) {
            .post-count {
                background-color: #f44336;
            }
            &:hover::after {
                background-color: #f44336;
            }
        }
        &:nth-child(8) {
            .post-count {
                background-color: #2196f3;
            }
            &:hover::after {
                background-color: #2196f3;
            }
        }
        &:nth-child(9) {
            .post-count {
                background-color: #8bc34a;
            }
            &:hover::after {
                background-color: #8bc34a;
            }
        }
        a {
            color: $title-color;
            display: block;
            padding-left: 80px;
        }
    }
}

.widget-post-list,
.widget-post-list-2 {
    li {
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0;
        }
        &.border {
            border-top: 1px solid $border-color;
            padding-top: 30px;
            .thumb {
                min-width: auto;
                width: auto;
            }
            .text {
                padding-left: 20px;
            }
        }
    }
    .thumb {
        min-width: 150px;
        width: 150px;
    }
    .text {
        padding-left: 30px;
    }
    .tag {
        display: inline-block;
    }
    .title {
        display: block;
    }
    time {
        font-size: 12px;
    }
}

.widget-post-list-2 {
    li {
        background-color: $gray-bg-3;
        padding: 20px;
        margin-bottom: 15px;
        @include transition();
        &:hover {
            box-shadow: 0 2px 5px 0 rgba($dark, 0.16), 0 2px 10px 0 rgba($dark, 0.12);
        }
    }
    .thumb {
        min-width: 100px;
        width: 100px;
    }
    .text {
        padding-left: 15px;
    }
}

.social-media {
    li {
        display: inline-block;
        a {
            border: 1px solid $border-color;
            border-radius: 5px;
            color: $title-color;
            display: block;
            font-size: 20px;
            font-weight: 400;
            padding: 10px 13px 8px;
            &:hover {
                background-color: $primary-color;
                border-color: $primary-color;
                color: $light;
            }
        }
        .follower-count {
            display: block;
            margin-top: 4px;
        }
    }
}

.social-media-2 {
    li:not(:last-child) {
        margin-bottom: 5px;
    }
    a {
        border-radius: $border-radius;
        display: block;
        line-height: 45px;
        &:hover, &:focus {
            color: #fff;
        }
    }
    .icon {
        border-right: 1px solid rgba($light, 0.2);
        float: left;
        font-size: 20px;
        width: 60px;
        text-align: center;
    }
    .user-count {
        float: left;
        padding-left: 25px;
    }
    .like-btn {
        color: rgba($light, 0.32);
        font-size: 18px;
        float: right;
        margin-right: 25px;
    }
}

.twitter-feed-widget {
    li {
        border-bottom: 1px solid $light;
        margin: 0 -30px 24px;
        padding: 0 30px 23px;
        &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
            margin-bottom: 0;
        }

        p {
            margin-bottom: 12px;
        }
    }
}

.widget-tag-cloud a {
    display: inline-block;
    padding: 6px 10px;
}

.instagram-widget {
    margin: -5px;
    li {
        display: inline-block;
        width: 33.3333%;
        a {
            display: block;
            margin: 5px;
        }
        img {
            width: 100%;
        }
    }
}

.dribble-shots {
    margin: -5px;
    li {
        display: inline-block;
        width: 33.3333%;
        a {
            display: block;
            margin: 5px;
        }
        img {
            width: 100%;
        }
    }
}

.twitter-feed-footer {
    li {
        margin-bottom: 27px;
        &:last-child {
            margin-bottom: 0;
        }
        p {
            margin-bottom: 15px;
        }
    }
}

.widget-post-slider {
    .owl-dots {
        left: 0;
        margin: 0;
        position: absolute;
        right: 0;
        top: 43%;
    }
}

.sitemap-widget {
    & > li {
        & > a {
            border-bottom: 1px solid $border-color;
            color: $title-color;
            display: block;
            margin-bottom: 15px;
            padding-bottom: 10px;
        }
    }
    .active > a {
        border-bottom-color: $title-color;
    }
    li li a {
        color: $text-color;
        display: block;
        line-height: 26px;
        &:hover {
            color: $primary-color;
        }
    }
}







