
//    Mixins

@mixin transition($args: all 0.3s ease 0s) {
  -webkit-transition: $args;
     -moz-transition: $args;
       -o-transition: $args;
          transition: $args;
}

@mixin transition-duration($args1, $args2) {
  -webkit-transition-duration: $args1, $args2;
     -moz-transition-duration: $args1, $args2;
       -o-transition-duration: $args1, $args2;
          transition-duration: $args1, $args2;
}

@mixin transition-delay($args1, $args2) {
  -webkit-transition-delay: $args1, $args2;
     -moz-transition-delay: $args1, $args2;
       -o-transition-delay: $args1, $args2;
          transition-delay: $args1, $args2;
}

@mixin transition-property($args1, $args2) {
  -webkit-transition-property: $args1, $args2;
     -moz-transition-property: $args1, $args2;
       -o-transition-property: $args1, $args2;
          transition-property: $args1, $args2;
}

@mixin transform($transform) {
    -webkit-transform: $transform;
       -moz-transform: $transform;
        -ms-transform: $transform;
         -o-transform: $transform;
            transform: $transform;
}

@mixin transform-origin($value) {
    -webkit-transform-origin: $value;
       -moz-transform-origin: $value;
        -ms-transform-origin: $value;
         -o-transform-origin: $value;
            transform-origin: $value;
}

@mixin backface-visibility($value) {
    -webkit-backface-visibility: $value;
       -moz-backface-visibility: $value;
            backface-visibility: $value;
}

@mixin perspective($value) {
    -webkit-perspective: $value;
       -moz-perspective: $value;
            perspective: $value;
}

@mixin calc ( $property, $expression ) {
    #{$property}: -webkit-calc(#{$expression});
    #{$property}: -moz-calc(#{$expression});
    #{$property}: calc(#{$expression});
}

@mixin keyframes ( $animation-name ) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name}  {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation ($args) {
  -webkit-animation: $args;
     -moz-animation: $args;
       -o-animation: $args;
          animation: $args;
}







