/*====================================
	Hero Slider 1
====================================*/
.hero-slider-1 {
	h1 {
		color: #fff;
		font-family: $roboto-slab;
		font-size: 60px;
		line-height: 1.2;
	}
	b {
		color: #77ffd9;
	}
	.btn {
		margin-top: 50px
	}
	.social-links {
		margin-top: 60px;
		position: relative;
		a {
			background-color: rgba(#000, 0.05);
			color: rgba(#000, 0.3);
			height: 40px;
			line-height: 40px;
			margin: 0 0.5px;
			position: relative;
			text-align: center;
			width: 40px;
			z-index: 1;
			&:hover {
				background-color: rgba(#000, 0.3);
				color: #fff;
			}
		}
	}
	.border-top,
	.border-bottom {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		&::before,
		&::after {
			content: "";
			height: 1px;
			position: absolute;
			top: 0;
			@include calc(width, "50% - 120px");
		}
		&::before {
			background: -webkit-linear-gradient(left, transparent, rgba(#000, 0.3)) repeat scroll 0 0;
			left: 120px;
		}
		&::after {
			background: -webkit-linear-gradient(left, rgba(#000, 0.3), transparent) repeat scroll 0 0;
			right: 120px;
		}
	}
	.border-top {
		border: 0;
	}
	.border-bottom {
		border: 0;
		bottom: 0;
		top: auto;
	}
	.slide-1 {
		background-color: #492c7e;
		img {
			max-height: 80vh;
		}
		.btn {
			background-color: #77ffd9;
			color: #492c7e;
		}
		.social-links {
			a {
				background-color: rgba(#fff, 0.1);
				color: #492c7e;
				&:hover {
					background-color: #77ffd9;
				}
			}
		}
		.border-top,
		.border-bottom {
			&::before {
				background: -webkit-linear-gradient(left, transparent, rgba(#fff, 0.3)) repeat scroll 0 0;
			}
			&::after {
				background: -webkit-linear-gradient(left, rgba(#fff, 0.3), transparent) repeat scroll 0 0;
			}
		}
	}

	.slide-2,
	.slide-3 {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		h1 b {
			color: #fff;
		}
	}

	.slide-3 {
		.slide-content {
		  	background-color: rgba(#000, 0.05);
		  	border-radius: 0 0 0 250px;
		  	margin: 0 0 0 50px;
		  	padding: 80px 70px;
		}
		.border-top,
		.border-bottom {
			&::before,
			&::after {
				@include calc(width, "50% - 50px");
			}
			&::before {
				left: 50px;
			}
			&::after {
				right: 50px;
			}
		}
	}

	.slick-dots {
		bottom: 30px;
		left: 50%;
		position: absolute;
		@include transform(translateX(-50%));
		li {
			border: 2px solid #fff;
			border-radius: 20px;
			cursor: pointer;
			display: inline-block;
			height: 20px;
			margin: 0 2.5px;
			opacity: 0.38;
			position: relative;
			width: 20px;
			@include transition();
			&::before {
				background-color: #fff;
				border-radius: 10px;
				content: "";
				height: 10px;
				left: 50%;
				position: absolute;
				top: 50%;
				width: 10px;
				@include transform(translate(-50%, -50%));
			}
		}
		.slick-active {
			opacity: 1;
		}
		button {
			display: none;
		}
	}
}

/*====================================
	Hero Slider 2
====================================*/
.hero-slider-2 {
	background-color: #ee363e;
	.parallax-content {
		h1 {
			font-size: 44px;
			line-height: 1.3;
		}
		p {
			margin-top: 15px;
		}
	}
}

/*====================================
	Hero Slider 3
====================================*/
.hero-slider-3 {
	.slide-item {
		background-attachment: fixed;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	h1 {
		font-size: 48px;
	}
	h3 {
		font-size: 24px;
	}

	.slick-dots {
		position: absolute;
		right: 100px;
		top: 50%;
		@include transform(translateY(-50%));
		li {
			border: 2px solid #fff;
			border-radius: 20px;
			cursor: pointer;
			height: 20px;
			margin: 5px 0;
			opacity: 0.38;
			position: relative;
			width: 20px;
			@include transition();
			&::before {
				background-color: #fff;
				border-radius: 10px;
				content: "";
				height: 10px;
				left: 50%;
				position: absolute;
				top: 50%;
				width: 10px;
				@include transform(translate(-50%, -50%));
			}
		}
		&.dark {
			li {
				border-color: #000;
				&::before {
					background-color: #000;
				}
			}
		}
		.slick-active {
			opacity: 1;
		}
		button {
			display: none;
		}
	}
}

/*====================================
	Hero Slider 4
====================================*/

.hero-slider-4-for {
	.slide-content {
		left: 50%;
		position: absolute;
		top: 50%;
		white-space: nowrap;
		@include transform(translate(-50%, -50%));
		.btn-border {
			border-color: $dark;
			height: 37px;
			line-height: 36px;
			padding: 0 37px;
		}
	}
	h1 {
		font-size: 42px;
	}
}

.hero-slider-4-nav {
	bottom: 40px;
	left: 50%;
	position: absolute;
	@include transform (translateX(-50%));
	.slick-slide {
		background-size: cover;
		background-repeat: no-repeat;
		border: 5px solid rgba(#fff, 0.5);
		cursor: pointer;
		height: 80px;
		margin: 0 2.5px;
		width: 80px;
		@include transition();
	}
	.slick-current {
		border-color: rgba($dark, 0.7);
	}
}

/*====================================
	Hero Slider 5
====================================*/

.hero-slider-5 {
	h1 {
		font-size: 48px;
		line-height: 1.25;
	}
	.coffee-cup {
		bottom: 40px;
		position: absolute;
		right: 0;
	}

	.slick-dots {
		position: absolute;
		right: 100px;
		top: 50%;
		@include transform(translateY(-50%));
		li {
			border: 2px solid #fff;
			border-radius: 20px;
			cursor: pointer;
			height: 20px;
			margin: 5px 0;
			opacity: 0.38;
			position: relative;
			width: 20px;
			@include transition();
			&::before {
				background-color: #fff;
				border-radius: 10px;
				content: "";
				height: 10px;
				left: 50%;
				position: absolute;
				top: 50%;
				width: 10px;
				@include transform(translate(-50%, -50%));
			}
		}
		&.dark {
			li {
				border-color: #000;
				&::before {
					background-color: #000;
				}
			}
		}
		.slick-active {
			opacity: 1;
		}
		button {
			display: none;
		}
	}
}

/*====================================
	Hero Slider 6
====================================*/

.hero-slider-6-for {
	.slide-content {
		
	}
	h1 {
		font-size: 42px;
		line-height: 1.2;
	}
	.social-links {
		a {
			border: 1px solid rgba(#fff, 0.3);
			color: #fff;
			display: block;
			font-size: 18px;
			height: 40px;
			line-height: 39px;
			text-align: center;
			margin: 0 1px;
			width: 40px;
			&:hover {
				background-color: #fff;
				border-color: #fff;
				color: #000;
			}
		}
	}
}

.hero-slider-6-nav {
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	@include transform(translateY(-50%));
	.slick-list {
		padding-top: 40px;
		padding-bottom: 40px;
	}
	.slick-track {
	  	@include flexbox();
	  	@include justify-content(center);
	}
	.slick-slide {
		background-size: cover;
		background-repeat: no-repeat;
		cursor: pointer;
		height: 80px;
		margin: 0 20px;
		width: 80px !important;
		@include transition(all 0.3s ease 0s);
		@include transition();
	}
	.slick-current {
		border-color: rgba($dark, 0.7);
		width: 80px !important;
		@include transform(scale(2));
	}
}

/*====================================
	Hero Slider 7
====================================*/

.hero-slider-7 {
	.slide-content {
		position: relative;
		h1 {
			font-size: 48px;
			line-height: 1.2;
		}
		&::before,
		&::after {
			background-repeat: no-repeat;
			background-size: contain;
			content: "";
			display: block;
			height: 110px;
			margin-left: auto;
			margin-right: auto;
			position: relative;
			width: 221px;
		}
		&::before {
			background-image: url("../img/hero-slider/slider-7/arrow-up.png");
			top: -24px;
		}
		&::after {
			background-image: url("../img/hero-slider/slider-7/arrow-down.png");
			bottom: -15px;
		}
	}
	.slick-dots-wrapper {
		background-color: rgba(#000, 0.7);
		border-left: 1px solid rgba(#fff, 0.5);
		bottom: 0;
		position: absolute;
		right: 0;
		top: 0;
		width: 100px;
	}
	.slick-prev,
	.slick-next {
		left: 50%;
		position: absolute;
		@include transform(translateX(-50%));
	}
	.slick-prev {
		top: 15%;
	}
	.slick-next {
		bottom: 15%;
	}
	.slick-dots {
		width: 100%;
		li {
			padding: 17px 0;
			position: relative;
			&::before {
				background-color: $primary-color;
				content: '';
				height: 0;
				position: absolute;
				left: -2px;
				top: 0;
				width: 3px;
				@include transition();
			}
		}
		.slick-active::before {
			height: 100%;
		}
		button {
			background-color: transparent;
			border: 0 none;
			color: #fff;
			display: block;
			font-size: 24px;
			padding: 0;
			width: 100%;
			&::before {
				content: "0";
			}
		}
	}
}

/*====================================
	Hero Slider 8
====================================*/

.hero-slider-8 {
	h3 {
		font-size: 30px;
		line-height: 1.2;
	}
	.slick-arrow {
		background-color: transparent;
		border: 0 none;
		height: 52px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		top: 50%;
		width: 135px;
		z-index: 1;
		@include transform(translateY(-50%));
		.icon {
			background-color: #fff;
			border: 1px solid rgba($dark, 0.15);
			border-radius: 50px;
			box-shadow: 0 1px 5px 0 rgba($dark, 0.2);
			font-size: 16px;
			height: 50px;
			line-height: 50px;
			position: absolute;
			right: 0;
			top: 1px;
			width: 50px;
			z-index: 1;
		}
		.text {
			background-color: #fff;
			border: 1px solid rgba($dark, 0.15);
			border-right: 0;
			border-radius: 50px 0 0 50px;
			position: absolute;
			font-size: 12px;
			height: 50px;
			letter-spacing: 4px;
			line-height: 49px;	
			text-transform: uppercase;
			top: 1px;
			width: 110px;
			@include transition();
		}
	}
	.slick-prev {
		left: 0;
		.icon {
			left: 0;
			right: auto;
		}
		.text {
			border-left: 0;
			border-radius: 0 50px 50px 0;
			border-right: 1px solid rgba($dark, 0.15);
			right: 0;
			padding-right: 24px;
			text-align: right;
			@include transform(scaleX(0));
			@include transform-origin(left top 0);
		}
		&:hover .text {
			@include transform(scaleX(1));
		}
	}
	.slick-next {
		right: 0;
		.text {
			left: 0;
			padding-left: 28px;
			text-align: left;
			@include transform(scaleX(0));
			@include transform-origin(right top 0);
		}
		&:hover .text {
			@include transform(scaleX(1));
		}
	}
}


/*====================================
	Hero Slider 10
====================================*/

.hero-slider-10-for {
	.slide-content {
		position: relative;
		&::before,
		&::after {
			background-color: #fff;
			content: "";
			height: 1px;
			left: 50%;
			position: absolute;
			width: 60px;
			@include transform(translateX(-50%));
		}
		&::before {
			top: -25px;
		}
		&::after {
			bottom: -25px;
		}

		h1 {
			font-size: 48px;
			line-height: 1.2;
		}
	}
}

.hero-slider-10-nav {
	background-color: rgba($dark, 0.7);
	border-left: 1px solid rgba(#fff, 0.5);
	bottom: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 120px;
	.slick-list {
		left: 50%;
		margin-left: -15px;
		padding-left: 30px;
		position: absolute;
		top: 50%;
		@include transform(translate(-50%, -50%));
	}
	.slick-slide {
		background-repeat: no-repeat;
		background-size: cover;
		height: 80px;
		margin: 5px 0;
		position: relative;
		width: 80px;
		&::before {
			background-color: #fff;
			content: "";
			height: 0;
			left: -22px;
			position: absolute;
			top: 0;
			width: 3px;
			@include transition();
		}
	}
	.slick-current::before {
		height: 100%;
	}
}


/*====================================
	Hero Slider 11
====================================*/

.hero-slider-11-for {
	h1 {
		font-size: 48px;
		line-height: 1.2;
	}
}

.hero-slider-11-nav {
	background-color: rgba($dark, 0.7);
	height: 120px;
	bottom: 0;
	left: 50%;
	position: absolute;
	width: 100%;
	@include flexbox();
	@include align-items(center);
	@include justify-content(center);
	@include transform (translateX(-50%));

	.slick-slide {
		background-size: cover;
		background-repeat: no-repeat;
		cursor: pointer;
		height: 80px;
		margin: 0 5px;
		position: relative;
		width: 80px !important;
		&::before {
			border: 5px solid #797878;
			content: "";
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			opacity: 0;
			@include transition();
		}
	}
	.slick-current::before {
		opacity: 1;
	}
}

/*====================================
	Hero Slider 12
====================================*/

.hero-slider-12 {
	.slide-content {
		h1 {
			font-size: 48px;
			font-weight: 400;
			line-height: 1.2;
		}
	}
	.slick-arrow {
		background-color: transparent;
		border: 0 none;
		height: 52px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		top: 50%;
		width: 135px;
		z-index: 1;
		@include transform(translateY(-50%));
		.icon {
			border: 1px solid;
			border-radius: 50px;
			box-shadow: 0 1px 5px 0 rgba($light, 0.2);
			color: $light;
			font-size: 16px;
			height: 50px;
			line-height: 50px;
			position: absolute;
			right: 0;
			top: 1px;
			width: 50px;
			z-index: 1;
		}
		.text {
			border: 1px solid;
			border-right: 0;
			border-radius: 50px 0 0 50px;
			color: $light;
			position: absolute;
			font-size: 12px;
			height: 50px;
			letter-spacing: 4px;
			line-height: 49px;	
			text-transform: uppercase;
			top: 1px;
			width: 110px;
			@include transition();
		}
	}
	.slick-prev {
		left: 1%;
		.icon {
			left: 0;
			right: auto;
		}
		.text {
			border-left: 0;
			border-radius: 0 50px 50px 0;
			border-right: 1px solid;
			right: 0;
			padding-right: 24px;
			text-align: right;
			@include transform(scaleX(0));
			@include transform-origin(left top 0);
		}
		&:hover .text {
			@include transform(scaleX(1));
		}
	}
	.slick-next {
		right: 1%;
		.text {
			left: 0;
			padding-left: 28px;
			text-align: left;
			@include transform(scaleX(0));
			@include transform-origin(right top 0);
		}
		&:hover .text {
			@include transform(scaleX(1));
		}
	}
	.slick-dots {
		bottom: 30px;
		left: 0;
		position: absolute;
		right: 0;
		@include align-items(center);
		@include flexbox();
		@include justify-content(center);
		button {
			display: none;
		}
		li {
			border: 1px solid #fff;
			border-radius: 15px;
			cursor: pointer;
			display: block;
			height: 16px;
			margin: 0 2px;
			opacity: 0.5;
			position: relative;
			width: 16px;
			@include transition();
			&::before {
				background-color: #fff;
				border-radius: 10px;
				content: "";
				height: 10px;
				left: 50%;
				position: absolute;
				top: 50%;
				width: 10px;
				@include transform(translate(-50%, -50%));
			}
		}
		.slick-active {
			opacity: 1;
		}
	}
}

/*====================================
	Hero Slider 13
====================================*/

.hero-slider-13 {
	.slide-content {
		background-color: rgba(255, 255, 255, 0.1);
		border: 1px solid rgba(255, 255, 255, 0.3);
		box-sizing: border-box;
		padding: 100px 50px;
		h1 {
			font-size: 36px;
			font-weight: 400;
			line-height: 1.2;
		}
	}
	.slick-arrow {
		background-color: transparent;
		border: 0 none;
		height: 52px;
		padding: 0;
		position: absolute;
		top: 50%;
		width: 135px;
		z-index: 1;
		@include transform(translateY(-50%));
		.icon {
			background-color: $light;
			border-radius: 50px;
			box-shadow: 0px 1px 5px 0px rgba($dark, 0.2);
			color: $title-color;
			font-size: 16px;
			height: 50px;
			line-height: 50px;
			position: absolute;
			right: 0;
			top: 1px;
			width: 50px;
			z-index: 1;
		}
		.text {
			background-color: $light;
			border: 1px solid rgba($dark, 0.1);
			border-right: 0;
			border-radius: 50px 0 0 50px;
			color: $title-color;
			position: absolute;
			font-size: 12px;
			height: 50px;
			letter-spacing: 4px;
			line-height: 49px;	
			text-transform: uppercase;
			top: 1px;
			width: 110px;
			@include transition();
		}
	}
	.slick-prev {
		left: 1%;
		.icon {
			left: 0;
			right: auto;
		}
		.text {
			border-left: 0;
			border-radius: 0 50px 50px 0;
			border-right: 1px solid rgba($dark, 0.1);
			right: 0;
			padding-right: 24px;
			text-align: right;
			@include transform(scaleX(0));
			@include transform-origin(left top 0);
		}
		&:hover .text {
			@include transform(scaleX(1));
		}
	}
	.slick-next {
		right: 1%;
		.text {
			left: 0;
			padding-left: 28px;
			text-align: left;
			@include transform(scaleX(0));
			@include transform-origin(right top 0);
		}
		&:hover .text {
			@include transform(scaleX(1));
		}
	}
	.slick-dots {
		bottom: 30px;
		left: 0;
		position: absolute;
		right: 0;
		@include align-items(center);
		@include flexbox();
		@include justify-content(center);
		button {
			display: none;
		}
		li {
			border: 1px solid #fff;
			border-radius: 15px;
			cursor: pointer;
			display: block;
			height: 16px;
			margin: 0 2px;
			opacity: 0.5;
			position: relative;
			width: 16px;
			@include transition();
			&::before {
				background-color: #fff;
				border-radius: 10px;
				content: "";
				height: 10px;
				left: 50%;
				position: absolute;
				top: 50%;
				width: 10px;
				@include transform(translate(-50%, -50%));
			}
		}
		.slick-active {
			opacity: 1;
		}
	}
}

/*====================================
	Hero Slider 14
====================================*/

.hero-slider-14 {
	.slide-content {
		h1 {
			font-size: 48px;
			font-weight: 400;
			line-height: 1.2;
		}
	}
	.slick-dots {
		bottom: 30px;
		left: 0;
		position: absolute;
		right: 0;
		@include align-items(center);
		@include flexbox();
		@include justify-content(center);
		button {
			display: none;
		}
		li {
			border: 1px solid #fff;
			border-radius: 15px;
			cursor: pointer;
			display: block;
			height: 16px;
			margin: 0 2px;
			opacity: 0.5;
			position: relative;
			width: 16px;
			@include transition();
			&::before {
				background-color: #fff;
				border-radius: 10px;
				content: "";
				height: 10px;
				left: 50%;
				position: absolute;
				top: 50%;
				width: 10px;
				@include transform(translate(-50%, -50%));
			}
		}
		.slick-active {
			opacity: 1;
		}
	}
}

/*====================================
	Hero Slider 15
====================================*/

.hero-slider-15 {
	.slide-content {
		h1 {
			font-size: 48px;
			font-weight: 400;
			line-height: 1.2;
		}
	}
	.slick-arrow {
		background-color: transparent;
		border: 0 none;
		height: 52px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		top: 50%;
		width: 135px;
		z-index: 1;
		@include transform(translateY(-50%));
		.icon {
			border: 1px solid;
			border-radius: 50px;
			box-shadow: 0 1px 5px 0 rgba($light, 0.2);
			color: $light;
			font-size: 16px;
			height: 50px;
			line-height: 50px;
			position: absolute;
			right: 0;
			top: 1px;
			width: 50px;
			z-index: 1;
		}
		.text {
			border: 1px solid;
			border-right: 0;
			border-radius: 50px 0 0 50px;
			color: $light;
			position: absolute;
			font-size: 12px;
			height: 50px;
			letter-spacing: 4px;
			line-height: 49px;	
			text-transform: uppercase;
			top: 1px;
			width: 110px;
			@include transition();
		}
	}
	.slick-prev {
		left: 1%;
		.icon {
			left: 0;
			right: auto;
		}
		.text {
			border-left: 0;
			border-radius: 0 50px 50px 0;
			border-right: 1px solid;
			right: 0;
			padding-right: 24px;
			text-align: right;
			@include transform(scaleX(0));
			@include transform-origin(left top 0);
		}
		&:hover .text {
			@include transform(scaleX(1));
		}
	}
	.slick-next {
		right: 1%;
		.text {
			left: 0;
			padding-left: 28px;
			text-align: left;
			@include transform(scaleX(0));
			@include transform-origin(right top 0);
		}
		&:hover .text {
			@include transform(scaleX(1));
		}
	}
	.slick-dots {
		bottom: 30px;
		left: 0;
		position: absolute;
		right: 0;
		@include align-items(center);
		@include flexbox();
		@include justify-content(center);
		button {
			display: none;
		}
		li {
			border: 1px solid #fff;
			border-radius: 15px;
			cursor: pointer;
			display: block;
			height: 16px;
			margin: 0 2px;
			opacity: 0.5;
			position: relative;
			width: 16px;
			@include transition();
			&::before {
				background-color: #fff;
				border-radius: 10px;
				content: "";
				height: 10px;
				left: 50%;
				position: absolute;
				top: 50%;
				width: 10px;
				@include transform(translate(-50%, -50%));
			}
		}
		.slick-active {
			opacity: 1;
		}
	}
	.slick-dots {
		bottom: 30px;
		left: 0;
		position: absolute;
		right: 0;
		@include align-items(center);
		@include flexbox();
		@include justify-content(center);
		button {
			display: none;
		}
		li {
			border: 1px solid #fff;
			border-radius: 15px;
			cursor: pointer;
			display: block;
			height: 16px;
			margin: 0 2px;
			opacity: 0.5;
			position: relative;
			width: 16px;
			@include transition();
			&::before {
				background-color: #fff;
				border-radius: 10px;
				content: "";
				height: 10px;
				left: 50%;
				position: absolute;
				top: 50%;
				width: 10px;
				@include transform(translate(-50%, -50%));
			}
		}
		.slick-active {
			opacity: 1;
		}
	}
}