/*==============================================
    Call to Action
===============================================*/
.cta {
    h2 {
        // font-weight: 100;
    }
    p {
        // font-size: 16px;
        // color: $text-light;
    }
    .lead {
        font-size: 18px;
        font-weight: 400;
        line-height: 32px;
        margin-bottom: 0;
    }
    a {
        font-size: 16px;
    }
    &.cta-one {
        padding: 100px 0;
    }
    &.cta-two {
        
    }
}
.cta-wide {
    h2 {
        b {
            font-weight: 900;
        }
    }
    p {
        margin-top: 30px;
    }
    a + a {
        margin-left: 10px;
    }
    .btn-white {
        margin-top: 42px;
        font-size: 14px;
        font-weight: 500;
    }
}
.cta-gray {
    background-color: rgba(#000, 0.1);
    h3 {
        color: $text-color;
        color: #848484;
        font-size: 28px;
        letter-spacing: 1.12px;
    }
}
.cta-gray-2 {
    background-color: rgba(#000, 0.54);
}
.cta-bakery {
    .cta-text,
    .cta-button {
        min-height: 150px;
        & > * {
            line-height: 150px;
        }
    }
    .cta-text {
        background-color: #212121;
    }
    h2 {
        color: $light;
    }
    .cta-button {
        background-color: $primary-color;
        font-size: 24px;
        font-weight: 500;
        position: relative;
        z-index: 2;
        &::before {
            border: 5px solid $light;
            bottom: 0;
            content: "";
            left: 0;
            opacity: 0;
            position: absolute;
            right: 0;
            top: 0;
            z-index: -1;
            @include transition(all 0.3s ease 0s);
        }
        &::after {
            background-color: #bf211c;
            bottom: 20px;
            content: "";
            left: 20px;
            opacity: 0;
            position: absolute;
            right: 20px;
            top: 20px;
            z-index: -1;
            @include transition(all 0.3s ease 0s);
        }
        &:hover {
            &::before {
                bottom: 10px;
                left: 10px;
                opacity: 1;
                right: 10px;
                top: 10px;
            }
            &::after {
                opacity: 1;
            }
        }
        a {
            color: $light;
            display: block;
        }
    }
}

.cta-event {
    padding: 50px 0;
    .cta-logo {
        position: relative;
        padding-right: 40px;
        &::after {
            background-color: $light;
            content: "";
            height: 100%;
            width: 2px;
            position: absolute;
            right: 41px;
            top: 0;
        }
        img {
            width: 180px;
        }
    }
    p {
        color: $light;
        font-size: 16px;
        line-height: 26px;
    }
}

.cta-wave {
    .cta-wave-svg {
        display: block;
        path {
            fill: $primary-color;
        }
    }
    .cta-inner {
        bottom: 40px;
        left: 0;
        position: absolute;
        right: 0;
    }
}