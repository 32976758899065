
/*==============================================
    Tabs
===============================================*/

/*---------------- Tab-1 ------------*/

.tab-1 {
    .nav-list {
        padding-right: 4px;
        li {
            a {
                color: $title-color;
                font-family: $roboto-slab;
                display: block;
                font-size: 20px;
                padding: 38px 0 35px;
                text-align: center;
                text-transform: uppercase;
            }
            .icon {
                background-color: #fff;
                border-radius: 50%;
                box-shadow: $box-shadow-1;
                display: block;
                font-size: 28px;
                width: 80px;
                height: 80px;
                line-height: 80px;
                margin: 0 auto 27px;
            }
            &.active {
                background-color: $light;
                span {
                    color: $primary-color;
                }
            }
        }
    }
    .tab-content {
        background-color: $light;
        box-shadow: $box-shadow-1;
        padding: 93px 110px 99px;
    }
    .tab-pane {
        h2 {
            font-size: 36px;
            margin-bottom: 36px;
        }
    }
}

/*---------------- Tab-2 ------------*/

.tab-2 {
    .tab-nav {
        margin-bottom: 80px;
        li {
            display: inline-block;
            margin-left: -4px;
            &:first-child {
                margin-left: 0;
            }
            a {
                border: 1px solid transparent;
                color: $text-color;
                display: block;
                font-family: $roboto-slab;
                font-size: 18px;
                line-height: 60px;
                padding: 0 80px;
                &:hover {
                    border-color: rgba($dark, 0.2);
                }
            }
            &.active {
                a {
                    color: $light;
                    background-color: $primary-color;
                    border-color: $primary-color;
                    box-shadow: $box-shadow-1;
                }
            }
        }
    }
    .tab-menu-list {
        li {
            font-family: $roboto-slab;
            font-size: 18px;
            line-height: 80px;
            padding: 0 50px;
            &:nth-child(2n+1) {
                background-color: $gray-bg-2;
            }
        }
        .dish-price {
            font-weight: 400;
        }
    }
}

/*---------------- Tab-3 ------------*/

.tab-3 {
    .nav-list {
        li {
            a {
                background-color: rgba($light, 0.54);
                color: $text-color;
                font-family: $roboto-slab;
                font-size: 16px;
                font-weight: 300;
                letter-spacing: 2.8px;
                margin-bottom: 1px;
                padding: 36px 50px;
                text-transform: uppercase;
            }
            .icon {
                font-size: 30px;
                margin-right: 35px;
            }
        }
        .active {
            a {
                background-color: rgba($light, 0.87);
                color: $primary-color;
            }
        }
    }
    .tab-content {
        background-color: rgba($light, 0.87);
        padding: 83px 80px 95px;
    }
    .tab-pane {
        display: block;
        height: 0;
        opacity: 0;
        overflow: hidden;
        &.active {
            height: auto;
            opacity: 1;
            overflow: visible;
        }
        img {
            margin-bottom: 68px;
        }
        h2 {
            font-size: 30px;
            letter-spacing: 2.8px;
            margin: 0 0 20px;
            text-transform: uppercase;
        }
        .edu-block {
            font-family: $roboto-slab;
            margin-bottom: 44px;
            &:last-child {
                margin-bottom: 0;
            }
            h3 {
                font-size: 24px;
                margin-bottom: 5px;
            }
            h4 {
                font-size: 16px;
                margin-bottom: 16px;
            }
            p {
                font-family: $font-stack;
            }
        }
    }
    .misc-slider-1 {
        img {
            margin-bottom: 33px;
        }
    }
}

/*---------------- Tab-4 ------------*/
.tab-4 {
    .nav-list {
        li {
            a {
                background-color: $light;
                color: $text-color;
                font-weight: 300;
                padding: 36px 40px;
            }
            .icon {
                font-size: 30px;
                margin-right: 35px;
            }
            .text {
                font-size: 14px;
                span {color: $primary-color;}
                p {
                    font-family: $roboto-slab;
                    font-size: 18px;
                    margin-top: 1px;
                }
            }
        }
        .active {
            a {
                color: $light;
                background-color: $primary-color;
                span {
                    color: $light;
                }
            }
        }
    }
    .tab-pane {
        .img {
            background-repeat: no-repeat;
            background-size: cover;
        }
        .content {
            background-color: $primary-color;
            padding: 75px 70px;
            span {
                display: block;
                font-size: 16px;
                margin-bottom: 10px;
            }
            h4 {
                font-size: 20px;
            }
            p {
                margin-top: 35px;
            }
        }
    }
}

/*---------------- Tab-5 ------------*/

.tab-5 {
    .nav-lists {
        li {
            display: inline-block;
            margin-right: 50px;
            &:last-child {
                margin-right: 0;
            }
            a {
                color: $title-color;
                font-size: 60px;
            }
        }
        .active {
            a {
                color: $primary-color;
            }
        }
    }
    .tab-pane {
        h3 {
            color: $text-color;
            font-size: 24px;
            margin-bottom: 20px;
        }
    }
}

/*---------------- Tab-6 ------------*/
.tab-6 {
    .nav-list {
        li {
            display: inline-block;
            margin-left: -4px;
            &:first-child {
                margin-left: 0;
            }
            a {
                background-color: $light;
                color: $text-color;
                display: block;
                padding: 12px 40px;
            }
        }
        .active {
            a {
                background-color: $primary-color;
                color: $light;
            }
        }
    }
    .tab-pane {
        
    }
}

.tab-7 {
    .tab-nav {
        li {
            display: inline-block;
            margin: 0 40px;
            a {
                color: $text-color;
                display: block;
                font-size: 48px;
            }
        }
        .active {
            a {
                color: $primary-color;
            }
        }
    }
}

.misc-slider-2 {
    bottom: 150px;
    left: 0;
    position: absolute;
    width: -webkit-calc(50% - 15px);
    width: -moz-calc(50% - 15px);
    width: calc(50% - 15px);
    .item {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 450px;
    }
    .owl-nav {
        margin: 0;
        div {
            left: 0;
            margin: 0;
            position: absolute;
            top: 50%;
            z-index: 10;
            opacity: 0;
            @include transform(translateY(-50%));
        }
        .owl-next {
            left: auto;
            right: 0;
        }
    }
    &:hover {
        .owl-nav div {
            opacity: 1;
        }
    }
}

.tab-8 {
    .tab-nav {
        border-bottom: 1px solid $border-color;
        li {
            display: inline-block;
            margin-left: -4px;
            a {
                color: $text-color;
                display: block;
                font-weight: 400;
                padding: 0 35px 25px;
                position: relative;
                &::before {
                    background-color: $primary-color;
                    bottom: -2px;
                    content: "";
                    height: 3px;
                    left: 0;
                    position: absolute;
                    width: 0;
                    @include transition();
                }
            }
        }
        .active a {
            color: $primary-color;
            &::before {
                width: 100%;
            }
        }
    }
}

.tab-9 {
    .tab-nav {
        li {
            a {
                border-bottom: 1px solid $text-color;
                border-bottom-color: $text-color;
                color: $text-color;
                display: block;
                margin-right: 10px;
                padding: 0 18px 3px;
                position: relative;
                &::before {
                    bottom: -3px;
                    content: "";
                    position: absolute;
                    left: 0;
                    height: 2px;
                    width: 0;
                    @include transition( all 0.2s linear 0s );
                }
            }
            &:last-child a {
                margin-right: 0;
            }
            .lean {
                display: block;
                left: 0;
                position: relative;
                @include transition( all 0.2s linear 0s );
            }
        }
        .active {
            .lean {
                left: -33px;
            }
        }
        &.dark {
            .active a {
                color: $dark;
                &::before {
                    background-color: $dark;
                    width: 100%;
                }
            }
        }
        &.primary {
            .active a {
                color: $primary-color;
                border-bottom-color: $primary-color;
                &::before {
                    background-color: $primary-color;
                    width: 100%;
                }
            }
        }
    }

    .tab-content {
        padding: 73px 50px 80px;
    }
}

.horiz-icon-tab-nav {
    li {
        + li {
            margin-left: 30px;
        }
        a {
            color: $dark;
            font-size: 18px;
            height: 170px;
            width: 170px;
            @include flexbox();
            @include justify-content (center);
            @include align-items (center);
            &:hover {
                background-color: $primary-color;
                color: $light;
            }
        }
        i {
            display: block;
            font-size: 48px;
            line-height: 1;
            margin-bottom: 12px;
        }
    }
    .active a {
        background-color: $primary-color;
        color: $light;
    }
}


.set-menu-1 {
    padding: 30px;
    .dish-images {
        img {
            width: 100%;
        }
    }
    .dish-info {
        h2 {
            font-size: 24px;
        }
        .price {
            font-family: $roboto-slab;
            font-size: 48px;
            font-weight: 400;
            line-height: 1;
            text-align: center;
        }
    }
}
