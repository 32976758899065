/*==============================================
    Section Title
===============================================*/
.section-title {
    margin-bottom: 100px;
    position: relative;
    &.m-half {
        margin-bottom: 65px;
    }
    &.no-margin {
        margin-bottom: 0;
    }
    h2 {
        font-weight: 100;
    }
    .icon {
        font-size: 60px;
        margin-top: 30px;
    }
    .sub {
        margin-top: 60px;
        + p {
            font-size: 18px;
        }
    }
    p {
        color: rgba($dark, 0.38);
        font-size: 16px;
        margin: 33px 0 0;
        line-height: 28px;
    }
    + hr {
        margin: 0;
    }
    .shade-text {
        color: $dark;
        font-size: 200px;
        font-weight: 700;
        left: 50%;
        line-height: 0.8;
        opacity: 0.1;
        position: absolute;
        top: 0;
        text-transform: uppercase;
        @include transform (translateX(-50%));
        + h2 {
            padding: 107px 0 0;
        }
        &.fruits {
            + h2 {
                margin: 60px 0 0;
            }
        }
    }
    &.style2 {
        padding: 40px 0 50px;
        margin-bottom: 0;
        position: relative;
        h3 {
            font-size: 24px;
            color: $light;
            margin-bottom: 15px;
        }
        p {
            color: $text-light;
            font-size: 16px;
        }
        .toggle-section-btn {
            bottom: 0;
            left: 50%;
            position: absolute;
            @include transform(translate(-50%, 50%));
            i {
                @include transition(all 0.3s ease 0s);
            }
            &.active {
                i {
                    @include transform(rotate(180deg) translateY(-4px));
                } 
            }
        }
    }
    &.style3 {
        h2 {
            color: $title-color;
            padding-bottom: 13px;
        }
        hr {
            border-color: rgba($dark, 0.1);
            margin: 0;
        }
        p {
            color: $title-color;
            font-size: 14px;
            line-height: 22px;
            margin-top: 18px;
        }
    }
    &.style4 {
        h2 {
            font-size: 36px;
            letter-spacing: 2.8px;
        }
        p {
            font-size: 14px;
        }
        .sep {
            background-color: $title-color;
            height: 3px;
            margin: 25px auto 0;
            width: 100px;
            &.left {
                margin-left: 0;
                float: none !important;
            }
            &.right {
                margin-right: 0;
                float: none !important;
            }
        }
    }
    &.style5 {
        p {
            font-size: 18px;
            margin-top: 0;
        }
    }
    &.style6 {
        h2 {
            font-size: 36px;
            + p {
                font-size: 16px;
            }
        }
        p {
            font-size: 18px;
            margin-top: 0;
        }
    }

    &.style7 {
        h3 {
            background: url("../img/wedding/11.png") no-repeat scroll center top transparent;
            font-size: 24px;
            padding: 44px 0;
        }
    }

    &.style8 {
        .shade {
            color: rgba($dark, 0.1);
            display: inline-block;
            font-size: 100px;
            line-height: .8;
            white-space: nowrap;
        }
        .title-color {
            font-size: 24px;
            left: 50%;
            position: absolute;
            top: 50%;
            white-space: nowrap;
            @include transform (translate(-50%, -50%));
            &.right {
                left: auto;
                right: 0;
                @include transform (translate(0px, -50%));
            }
        }
    }

    &.style9 {
        h4 {
            &::before,
            &::after {
                background-color: rgba($dark, 0.38);
                content: '';
                height: 1px;
                position: absolute;
                width: 50px;
            }
            &::before {
                top: -10px;
                left: 0;
            }
            &::after {
                bottom: -9px;
                right: 0;
            }
        }
    }

    &.white {
        background-color: transparent !important;
        .icon, h2 {
            color: $light;
        }
        p {
            color: $text-light;
        }
        .sep {
            background-color: $light;
        }
    }
}

.sub-title {
    margin-bottom: 60px;
    h2 {
        font-size: 32px;
    }
    &.style2 {
        font-family: $roboto-slab;
        h2 {
            font-weight: 300;
        }
    }
}

.heading-s3 {
    background-color: $gray-bg-3;
    padding: 18px 30px 19px 30px;
}







