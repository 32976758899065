/*==============================================
    Site Header
===============================================*/
.site-header {
    background-color: transparent;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
    .mid-content {
        .left-content {
            color: $text-light;
        }
    }
    .currency-select,
    .lang-select {
        display: inline-block;
        white-space: nowrap;
    }
    .nice-select {
        background-color: transparent;
        border: 0 none;
        border-radius: 0;
        float: none;
        height: auto;
        line-height: inherit;
        z-index: 99;
        &::after {
            border-bottom-color: $text-light;
            border-right-color: $text-light;
        }
        span {
            color: $text-light;
            font-size: 13px;
        }
        &.dark {
            &::before,
            &::after {
                border-bottom-color: rgba(#000, 0.87);
                border-right-color: rgba(#000, 0.87);
            }
            span {
                color: $text-color;
            }
        }
    }
    .header-cart {
        display: inline-block;
        position: relative;
        white-space: nowrap;
    }
    .cart-button {
        background-color: $primary-color;
        color: $text-light;
        display: inline-block;
        padding: 0 38px;
    }
    .mid-content {
        float: right;
        margin: 8px 0;
    }
    .middle-search {
        background-color: $light;
        border-radius: $border-radius;
        min-width: 580px;
        position: relative;
        @include flexbox();
        @include align-items(center);
        input {
            border-bottom: 0;
            font-size: 14px;
            height: 50px;
            margin: 0;
            &:focus {
                border-bottom: 0;
                box-shadow: none;
            }
        }
        label {
            font-size: 24px;
            height: 50px;
            margin: 0;
            text-align: center;
            width: 50px;
            min-width: 50px;
            i {
                line-height: 50px;
            }
        }
    }
    &.is-sticky {
        //border-bottom: 1px solid $border-color;
        box-shadow: $box-shadow-1;
        position: fixed;
        -webkit-animation: .6s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s alternate none 1 running fadeInDown;
        animation: .6s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s alternate none 1 running fadeInDown;
        z-index: 150;
    }
    &.static,
    &.sticky {
        background-color: #fff;
        position: relative;
        z-index: 100;
        animation: none;
    }
    &.bottom-border {
        border-bottom: 1px solid $border-color;
    }
    &.bakery-gray {
        background-color: #e9eef1;
    }
    &.no-shadow,
    &.is-sticky.no-shadow {
        box-shadow: none;
    }
    /**-*-*-*-*-*- Header 3 -*-*-*-*-*-*-**/
    &.style3 {
        background-color: rgba($light, 0.05);
        border-bottom: 1px solid rgba($light, .05);
        &.is-sticky {
          background-color: $primary-color;
        }
    }
}

/*----------------------------------------
    Header action buttons
------------------------------------------*/


// header Sticky
//------------------------------------
.is-sticky {
    .is-sticky {
        box-shadow: $box-shadow-1;
    }
    .site-logo,
    .menu-list > li > a,
    .header-right > div > a,
    .nav-hamburger  {
        line-height: 65px;
        height: 65px;
    }
    .menu-list > li > a {
        color: #fff;
    }
    .top-search,
    .top-cart {
        height: 65px;
    }
    .site-logo img {
        height: 30px;
    }
}

.menu-dark {
    .header-right div > a,
    .menu-list > li > a {
        color: $text-color;
    }
}

.more-vert {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    z-index: 10;
    & > a {
        color: $text-color;
        display: inline-block;
        font-size: 24px;
    }
    &:hover .more-vert-menu {
        opacity: 1;
        visibility: visible;
        @include transform (scale(1) translate(0px));   
    }
}

.more-vert-menu {
    background-color: $light;
    border-radius: $border-radius;
    box-shadow: $box-shadow-1;
    min-width: 150px;
    opacity: 0;
    padding: 15px;
    position: absolute;
    right: 0;
    top: 100%;
    @include transform (scale(0.7) translate(20px, -50px));
    @include transition (all 0.2s cubic-bezier(0.5, 0, 0, 1.25) 0s);
    visibility: hidden;
    z-index: 9;
    & > li > a {
        color: $text-color;
        display: block;
        font-size: 13px;
        padding: 2px 0;
    }
}

.more-vert.active {
    .more-vert-menu {
        
    }
}

.select-wrapper {
    .caret {
        border: 0 none;
        height: auto;
        margin-left: 0;
        width: auto;
    }
}


/*----------------------------------------
    Logo
------------------------------------------*/

.site-logo {
    float: left;
    line-height: 95px;
    position: relative;
    z-index: 9;
    img {
        height: 53px;
        @include transition(height 0.3s ease 0s);
    }
    .logo2 {
        display: none;
    }
}


/*==============================================
    Sticky Header
===============================================*/

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.is-sticky {
    background-color: $primary-color;
    .site-logo {
        .logo1 {display: none;}
        .logo2 {display: inline-block;}
    }
    .site-logo,
    .header-right > div:not(.active) > a {
        color: #fff;
    }
    .nav-hamburger {
        span, span::before , span::after {
            background-color: #fff;
        }
    }
}

/*----------------------------------------
    Nav Toggle
------------------------------------------*/

.nav-hamburger {
    background-color: transparent;
    border: medium none;
    float: right;
    height: 95px;
    margin-left: 30px;
    padding: 0;
    position: relative;
    text-indent: -9999px;
    width: 30px;
    @include transition(all 0.3s ease 0s);
    &:focus {
        background-color: transparent;
    }
    span {
        background-color: $light;
        display: block;
        height: 2px;
        left: 0;
        margin-top: -1px;
        position: absolute;
        right: 0;
        top: 50%;
        @include transition(background 0.3s ease 0s);
        &:before, &:after {
            position: absolute;
            display: block;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $light;
            content: "";
            @include transition-duration(0.3s, 0.3s);
            @include transition-delay(0.3s, 0s);
        }
        &:before {
            top: -7px;
            @include transition-property(top, transform);
        }
        &:after {
            bottom: -7px;
            @include transition-property(bottom, transform);
        }
    }
    &.dark {
        span {
            background-color: $text-color !important;
            &::before,
            &::after {
                background-color: $text-color !important;
            }
        }
        &.active {
            span {
                background-color: transparent !important;
            }
        }
    }
    &.active {
        span {
            background-color: transparent;
            &:before, &:after {
                @include transition-delay(0s, 0.3s);
            }
            &:before {
                top: 0;
                @include transform(rotate(45deg));
            }
            &:after {
                bottom: 0;
                @include transform(rotate(-45deg));
            }
        }
    }
}

/*----------------------------------------
    Primary Nav 
------------------------------------------*/

.primary-nav {
    float: left;
    position: static !important;
    padding: 0 0 0 50px;
    .active {
        background-color: transparent;
        a {

        }
    }
    li {
        position: relative;
        a {
            display: block;
            font-weight: 400;
            position: relative;
        }
    }
    &.center {
        float: none;
        .menu-list {
            & > li {
                float: none;
                display: inline-block;
            }
        }
    }
}
.top-cart, .top-search{
  text-align: center;
  a{
    i{
      font-size: 20px;
      margin: 0 auto;
    }
  }
}
.menu-list {
    & > li {
        float: left;
        position: relative;
        & > a {
            font-size: 14px;
            line-height: 95px;
            padding: 0 15px;
            position: relative;
            text-transform: uppercase;
        }
    }
    li.megamenu {
        position: static;
    }
}

/*----------------- DropDown ---------------*/

.menu-list {
    .sub-menu {
        background-color: #fff;
        left: 0;
        min-width: 190px;
        opacity: 0;
        position: absolute;
        text-align: left;
        top: 100%;
        visibility: hidden;
        white-space: nowrap;
        width: 100%;
        z-index: 12;
        @include transition( all 0.2s ease 0s );
        & > li {
            float: none;
            & > a {
                line-height: inherit;
                padding: 7.5px 25px 7.5px 22px;
            }
        }
        .sub-menu {
            top: 0;
            left: 100%;
        }
    }
}
.has-dropdown {
    &:hover > .sub-menu {
        opacity: 1;
        visibility: visible;
        z-index: 99;
    }
    .dropdown-holder {
        display: none;
    }
}

.sub-menu {
    & > li {
        .arrow {
            font-size: 16px;
            position: absolute;
            right: 24px;
            top: 50%;
            @include transform(translateY(-50%));
        }
    }
}

.sub-menu.megamenu-wrapper {
    min-width: auto;
    padding: 20px 0;
    text-transform: uppercase;
    & > li {
        // min-width: 180px;
        width: 100%;
        a {
            font-size: 11px;
            text-transform: none;
        }
    }
}
.megamenu-child {
    width: 100%;
    text-align: center !important;
    li {
      display: inline-block;
      a {
        padding: 3px 10px;
        position: relative;
        font-size: 12px;
        font-weight: 600;
        color: #000;
        -webkit-transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
        -ms-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        &:hover {
            color: $primary-color;
        }
      }
      &.active a{
        color: $primary-color;
      }
    }
}

/*----------------- Menu Hover ---------------*/

.nav-hover-1 {
    & > li {
        & > a {
            font-size: 12px;
        }
        &.active {
          & > a {
            background-color: $primary-color;
            color: #fff;
          }
          & > .sub-menu {
            opacity: 1;
            visibility: visible;
          }
        }
        &:hover,
        &:focus {
            & > a {
                background-color: $primary-color;
                color: #fff;
            }
        }
    }
    /*
    .sub-menu {
        li {
            a {
                font-size: 13px;
            }
            &:hover > {
                a {
                    background-color: $primary-color;
                    color: $light;
                }
            }
        }
    }*/
}



/*----------------------------------------
    Header right
------------------------------------------*/
.header-right {
    float: right;
    position: relative;
    .devider {
        background-color: $light;
        height: 25px;
        left: 0;
        margin-top: -12.5px;
        position: absolute;
        top: 50%;
        width: 1px;
    }
    .top-cart,
    .top-search {
        float: left;
    }
    .top-cart {
        position: relative;
    }
    .top-cart > a,
    .top-search > a {
        color: $light;
        display: block;
        height: 95px;
        font-size: 24px;
        line-height: 95px;
        padding: 0 15px;
    }
}



/*==============================================
    Side Nav
===============================================*/

.side-nav-wrap {
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 240px;
    z-index: 50;

    @include transform (translateX(-100%));
    @include transition (all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0s);

    &.opened {
        @include transform (translateX(0px));
        + .sidenav-overlay {
            opacity: 1;
            position: fixed;
            visibility: visible;
        }
    }

    .user-view {
        background: #fff url("") no-repeat scroll center center / cover;
        padding: 30px 30px 20px;
        &.logo-only {
            padding: 60px 30px;
        }
        a {
            border: 0 none;
        }
    }

    .logo-only {
        img {
            height: 53px;
        }
    }

    &.primary {
        background-color: $primary-color;
        .user-view {
            background-color: $primary-color;
        }
        a {
            color: $light;
        }
    }
}

.sidenav-overlay {
    opacity: 0;
    visibility: hidden;
    z-index: 40;
    @include transition (all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0s);
}

.toggle-side-nav {
    background-color: $primary-color;
    border-radius: 0 30px 30px 0;
    color: $light;
    font-size: 30px;
    height: 50px;
    left: 240px;
    line-height: 50px;
    width: 57px;
    position: absolute;
    text-align: center;
    top: 60px;
    z-index: 1000;
    
    .bar {
        background-color: #fff;
        height: 3px;
        left: 12px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 22px;
        
        &::before, &::after {
            background-color: #fff;
            content: "";
            height: 3px;
            left: 0;
            position: absolute;
            top: -7px;
            width: 100%;
        }

        &::after {
            top: auto;
            bottom: -7px;
        }
    }

    &.active {
        background-color: #425963;
        border-radius: 30px 0 0 30px;
        @include calc (left, "100% - 55px");
        span {
            left: 17px;
            right: 13px;
        }
    }
}

.nav-content {
    max-height: 500px;
    overflow: auto;
    .megamenu-title {
        display: none;
    }
    li a {
        display: block;
        font-size: 12px;
        font-weight: 400;
        height: auto;
        position: relative;
        text-transform: uppercase;
    }
    > li > a {
        border-top: 1px solid rgba($light, 0.3);
        line-height: 45px;
        padding: 0 30px;
    }
    &.primary {
        li:hover, li.active {
            & > a {
                background-color: $light;
                box-shadow: 0px 3px 4.7px 0.3px rgba($dark, 0.14);
                color: $primary-color;
            }
        }
    }
    .sub-menu {
        display: none;
        li li a {
            padding-left: 25px;
        }
    }
}


.c-hamburger {
    overflow: hidden;
    padding: 0;
    width: 55px;
    height: 50px;
    text-indent: -9999px;
    appearance: none;
    border: none;
    @include transition (all 0.3s ease 0s);

    span {
        display: block;
        position: absolute;
        top: 50%;
        left: 13px;
        right: 17px;
        height: 2px;
        background-color: $light;
        @include transition (background 0s 0.3s);
        @include transform (translateY(-50%));

        &::before,
        &::after {
            position: absolute;
            display: block;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $light;
            content: "";
            @include transition-duration (0.3s, 0.3s);
            @include transition-delay (0.3s, 0s);
        }

        &::before {
            top: -7px;
            @include transition-property (top, transform);
        }

        &::after {
            bottom: -7px;
            @include transition-property (bottom, transform);
        }
    }

    &.active {
        span {
            background-color: transparent;

            &::before,
            &::after {
                transition-delay: 0s, 0.3s;
            }

            &::before {
                top: 0;
                transform: rotate(45deg);
            }

            &::after {
                bottom: 0;
                transform: rotate(-45deg);
            }
        }
    }
}

.toggle-detault {
    background-color: transparent;
    background-image: none;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    float: right;
    margin-bottom: 8px;
    margin-right: 15px;
    margin-top: 8px;
    padding: 9px 10px;
    position: relative;
    .icon-bar {
        background-color: $text-color;
        border-radius: 1px;
        display: block;
        height: 2px;
        width: 22px;
    }
}

.navbar-form {
    &.navbar-right {
        padding-right: 0;
    }
    input {
        margin-bottom: 0;
    }
}
.new {
   background: #f6514c none repeat scroll 0 0;
   border-radius: 3px;
   color: #fff;
   display: inline-block;
   padding: 2px 7px;
   line-height: 1.2em;
   text-transform: capitalize;
   font-size: 10px;
   margin-left: 5px;
}
