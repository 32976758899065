
/*==============================================
    Testimonial
===============================================*/
.tm-carousel-2,
.tm-carousel-3,
.tm-carousel-4 {
    .owl-stage-outer {
        padding: 1px 0px 30px 1px;
    }
}
.tm-carousel-3 {
    .owl-dots {
        margin-top: 40px;
    }
    .owl-dot {
        opacity: 0.38;
        &.active {
            opacity: 1;
        }
    }
}
.tm-carousel-4 {
    /*.owl-nav {
        bottom: 10px;
        left: 0;
        margin-top: 0;
        position: absolute;
        right: 0;
    }*/
}
.tm-carousel-5,
.tm-carousel-7 {
    .owl-stage-outer {
        padding: 5px 0 30px;
    }
}

.tm-carousel-12 {
    background-color: $light;
    @include transition(all .3s);
    &:hover {
        box-shadow: $hover-shadow;
    }
    .owl-dots {
        left: 0;
        position: absolute;
        right: 0;
    }
}

.tm-title {
    color: $light;
    h2 {
        color: $light;
        font-size: 46px;
        line-height: 50px;
        margin-top: 35px;
    }
    p {
        font-size: 16px;
        font-style: italic;
        line-height: 26px;
        margin-top: 26px;
    }
}
.tm-item {
    &.style1 {
        text-align: center;
        .chip {
            background-color: $light;
            border-radius: 50px;
            font-size: 14px;
            height: 50px;
            line-height: 50px;
            margin-bottom: 30px;
            padding-right: 35px;
            img {
                height: 50px;
                margin-right: 20px;
                width: 50px;
            }
        }
        p {
            color: $text-light;
            font-size: 16px;
        }
    }
    &.style2,
    &.style4 {
        background-color: $light;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
        padding: 48px 35px 40px;
        position: relative;
        &::before, &::after {
            background-color: $light;
            box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
            content: '';
            position: absolute;
            left: 10px;
            bottom: -10px;
            right: 10px;
            height: 10px;
            z-index: -1;
        }
        &::after {
            bottom: -20px;
            left: 20px;
            right: 20px;
            z-index: -2;
        }
        i {
            font-size: 36px;
            margin-bottom: 14px;
        }
        blockquote {
            border: 0;
            margin: 0;
            padding: 0;
        }
        p {
            color: $title-color;
            font-size: 20px;
            font-style: italic;
            font-weight: 500;
            line-height: 32px;
        }
        .author-info {
            text-align: center;
            margin-top: 24px;
            span {
                display: block;
                font-weight: 500;
                text-transform: uppercase;
                a {color: $title-color;}
            }
            .name {
                color: $primary-color;
            }
        }
    }
    &.style3 {
        background-color: $light;
        padding: 44px 30px 50px;
        margin-bottom: 30px;
        p {
            color: $title-color;
            font-size: 16px;
        }
        .media-left {
            padding-right: 15px;
            img {
                width: 80px;
                height: 80px;
            }
        }
        .media-body {
            vertical-align: middle;
            a {
                color: $title-color;
                &:hover {
                    color: $primary-color;
                }
            }
        }
        span {
            display: block;
        }
        .name {
            color: $primary-color;
            font-size: 16px;
        }
    }
    &.style4 {
        margin: 0 7px;
        padding: 85px 50px 50px;
        p {
            font-size: 14px;
            font-weight: 300;
        }
        span {
            display: inline-block;
            text-transform: uppercase;
            white-space: nowrap;
            span { color: $title-color;}
        }
    }
    &.style5 {
        .thumb {
            margin-bottom: 24px;
            img {
                margin: 0 auto;
                width: auto;
            }
            svg {
                width: 100px;
            }
        }
        .border-shape {
            stroke: $light;
            fill: $light;
        }
        .meta {
            margin-bottom: 44px;
        }
    }
    &.style6 {
        background-color: #fcfcfc;
        padding: 50px 53px 60px;
        box-shadow: $normal-shadow;
        &:hover {
            box-shadow: $hover-shadow;
        }
        @include transform(scale(0.95));
        .thumb {
            width: 60px;
            height: 60px;
            margin: 0 auto 35px;
        }
        img {
            border-radius: 50%;
            height: 100%;
            width: 100%;
        }
        p {
            color: $title-color;
            font-size: 16px;
            font-style: italic;
            font-weight: 500;
            line-height: 30px;
        }
        h4 {
            color: $primary-color;
            font-size: 16px;
            font-weight: 500;
        }
        &:hover {
            background-color: $light;
        }
    }
    &.style7 {
        .thumb {
            min-width: 220px;
            padding-right: 30px;
        }
        h3 {
            font-size: 24px;
        }
    }
    &.style8 {
        border: 1px solid $border-color;
        border-radius: $border-radius;
        padding: 45px 60px;
        margin-bottom: 30px;
        opacity: 0.2;
        @include transition(all 0.4s ease 0s);
        &.slick-center {
            box-shadow: 0px 2px 4.7px 0.3px rgba($dark, 0.24);
            border-color: transparent;
            opacity: 1;
        }
        blockquote {
            border: 0 none;
            color: $title-color;
            font-size: 18px;
            font-style: italic;
            line-height: 31px;
            padding: 0;
        }
        .thumb {
            padding-right: 20px;
        }
        img {
            height: 60px;
            width: 60px;
        }
        h4 {
            font-size: 16px;
        }
        a {
            color: $text-color;
            &:hover {
                color: $primary-color;
            }
        }
    }
    &.style9 {
        border-bottom: 5px solid $border-color;
        padding-bottom: 50px;
        @include transition(border-color 0.4s ease 0s);
        p {
            font-size: 16px;
            font-style: italic;
            font-weight: 300;
            line-height: 30px;
            margin-bottom: 30px;
        }
        img {
            float: left;
            height: 60px;
            margin-right: 20px;
            width: 60px;
        }
        .name {
            color: $primary-color;
            font-size: 16px;
        }
        a {
            color: $text-color;
        }
        &:hover {
            border-bottom-color: $primary-color;
        }
    }
}

.tm-item-10 {
    padding: 6px;
    blockquote {
        border-radius: 5px;
        border: 0 none;
        font-size: 14px;
        font-style: italic;
        padding: 53px 50px;
    }
    .caret-bottom {
        clip: rect(15px, 40px, 45px, -15px);
        left: 65px;
        opacity: 1;
        position: absolute;
        @include calc (top, "100% - 15px");
        &::before {
            background-color: $light;
            box-shadow: $shadow-default;
            box-sizing: border-box;
            display: block;
            content: "";
            height: 30px;
            width: 30px;
            @include transition();
            @include transform(rotate(45deg));
        }
    }
    img {
        height: 60px;
        width: 60px;
    }
    &:hover {
        blockquote {
            background-color: $primary-color;
            color: $light;
        }
        h5 {
            color: $primary-color;
        }
        .caret-bottom::before {
            background-color: $primary-color;
        }
    }
}

.tm-item-11 {
    background-color: $light;
    padding: 60px 50px;
    box-shadow: $normal-shadow;
    blockquote {
        border: 0;
        color: $title-color;
        font-size: 14px;
        font-style: italic;
        margin-bottom: 43px;
        padding: 0;
    }
    .et-line {
        font-size: 30px;
    }
}

.tm-item-12 {
    .thumb {
        min-width: 260px;
        padding: 5px;
    }
    .text {
        margin: 0 30px 0 60px;
    }
    blockquote {
        border: 0 none;
        font-size: 14px;
        font-style: italic;
        margin: 0 0 25px;
        padding: 0;
    }
}

.tm-4-title {
    left: 70px;
    position: absolute;
    top: 40px;
    z-index: 9;
}

.tm-carousel-6 {
    .owl-nav {
        display: none;
    }
    &.ctrl-nav-3 {
        .owl-nav {
            display: block;
            margin: 0;
        }
        .owl-prev,
        .owl-next {
            background-color: $gray-bg-3;
            border-radius: 3px;
            box-shadow: none;
            height: 50px;
            left: 0;
            position: absolute;
            top: 50%;
            width: 50px;
            @include transform(translateY(-50%));
            @include transition(all 0.3s ease 0s);
            i {
                color: $text-color;
                font-size: 36px;
                line-height: 50px;
            }
            &:hover {
                background-color: $primary-color;
                i {
                    color: $light;
                }
            }
        }
        .owl-next {
            left: auto;
            right: 0;
        }
    }
}

.tm-carousel-8 {
    .slick-list {
        padding: 0 17px !important;
    }
    .slick-dots {
        position: absolute;
        right: -70px;
        top: 50%;
        @include transform(translateY(-50%));
        li {
            display: block;
        }
    }
}

.tm-carousel-11 .owl-dots {
    left: 0;
    margin-top: 50px;
    position: absolute;
    right: 0;
}

.tm-carousel-13 {
    .owl-stage-outer {
        padding-bottom: 50px;
    }
    .active + .active .tm-13 {
        // margin-left: 5px;
    }
    .owl-dots {
        @include flexbox();
        @include justify-content(center);
    }
    .owl-dot {
        background-color: #ccc;
        border-radius: 2px;
        height: 4px;
        margin: 0 5px;
        width: 15px;
        @include transition(all 0.2s linear 0s);
        &.active {
            background-color: $primary-color;
        }
    }
}

.tm-13 {
    background-color: #f9fafc;
    margin: 13px 25px 30px 40px;
    @include transition(all 0.2s linear 0s);
    .thumb {
        min-width: 130px;
    }
    img {
        border: 10px solid #fff;
        border-radius: 0 50% 50%;
        box-shadow: $normal-shadow;
    }
    .text {
        padding: 42px 50px 46px 40px;
        letter-spacing: 0;
    }
    &:hover {
        background-color: #fff;
        box-shadow: $hover-shadow;
    }
}
