
/*==============================================
    Counter
===============================================*/

/*----------------------------------------
    Counter style-1
------------------------------------------*/

.counter {
    &.style-1 {
        .odometer {
            color: $primary-color;
            display: block;
            font-size: 70px;
            font-weight: 100;
            line-height: 1;
        }
        p {
            font-size: 20px;
            margin-top: 17px;
        }
    }
    &.style-2,
    &.style-4,
    &.style-5 {
        border-left: 2px solid $light;
        color: $light;
        padding-left: 30px;
        padding-right: 30px;
        .odometer {
            color: $primary-color;
            font-family: $roboto-slab;
            font-size: 72px;
            font-weight: 400;
            line-height: 1;
            margin-bottom: 7px;
        }
        h4 {
            color: $light;
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 16px;
        }
        p {
            font-size: 13px;
        }
    }
    &.style-3 {
        h4 {
            font-size: 20px;
        }
        .sep {
            background-color: #dedede;
            height: 3px;
            margin-top: 15px;
            width: 50px;
        }
        .odometer {
            font-size: 48px;
            line-height: 1;
            margin-top: 20px;
        }
    }
    &.style-4 {
        .odometer {
            color: $light;
        }
    }
    &.style-5 {
        border-left-color: $primary-color;
        h4 {
            color: $title-color;
        }
    }

    &.style-6 {
        .icon {
            color: $primary-color;
            font-size: 36px;
        }
        .odometer {
            font-size: 60px;
            line-height: 1;
        }
    }
}

.counter-4-bg {
    background-position: center -50px;
    background-repeat: no-repeat;
}