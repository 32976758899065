/*==============================================
    client
===============================================*/
.client-logos {
    padding: 100px 0;
}

.client-logo {
    img {
        display: block;
        margin: 0 auto;
        opacity: 0.5;
        max-width: 100%;
        @include transition(opacity 0.3s ease 0s);
    }
    &:hover {
        img {
            opacity: 1;
        }
    }
}

.clients-3col {
    [class^="col-"] {
        border-bottom: 1px solid rgba($dark, 0.1);
        border-right: 1px solid rgba($dark, 0.1);
        min-height: 250px;
        &:nth-child(3),
        &:nth-child(6) {
            border-right: 0;
        }
        &:nth-last-child(1),
        &:nth-last-child(2),
        &:nth-last-child(3) {
            border-bottom: 0;
        }
        &:hover {
            img {
                opacity: .5;
            }
        }
    }
    img {
        opacity: 0.1;
    }
}

.clients-4col {
    [class^="col-"] {
        border-bottom: 1px solid rgba($dark, 0.1);
        border-right: 1px solid rgba($dark, 0.1);
        min-height: 250px;
        &:nth-child(4),
        &:nth-child(8) {
            border-right: 0;
        }
        &:nth-last-child(1),
        &:nth-last-child(2),
        &:nth-last-child(3),
        &:nth-last-child(4) {
            border-bottom: 0;
        }
        &:hover {
            background-color: $gray-bg-3;
            img {
                opacity: .5;
            }
            &.no-hover-bg {
                background-color: transparent;
            }
        }
    }
    &.light {
        [class^="col-"] {
            border-color: rgba($light, 0.1);
            border-color: rgba($light, 0.1);
        }
    }
    img {
        opacity: 0.25;
    }
}

.clients-6col {
    [class^="col-"] {
        min-height: 200px;
        padding: 25px;
        &:hover {
            img {
                opacity: .5;
            }
        }
    }
    img {
        opacity: 0.1;
    }
}