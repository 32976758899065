
.footer {
    background-color: #101010;
    padding: 30px 0 25px;
    &.bg-color-2 {
        background-color: #21282f;
        border-top: 1px solid rgba($light, 0.1);
    }
}

.primary-footer {
    &.dark {
        background-color: #171b22;
    }
}

.secondery-footer {
    &.dark {
        background-color: #13171c;
    }
    .inner-footer {
        padding: 30px 0;
    }
    .border-top {
        border-top: 1px solid $border-color;
    }
    p {
        margin-top: 9px;
    }
}

.footer-light {
    background-color: $light;
    padding: 52px 0 53px;
    .copyright {
        color: $text-color;
        font-size: 12px;
        font-weight: 400;
        text-transform: uppercase;
    }
}

.copyright {
    color: $text-light;
}

.footer-nav {
    ul {
        font-size: 0;
    }
    li {
        display: inline-block;
        a {
            color: $text-light;
            font-size: 14px;
            padding: 0 15px;
            &:hover {
                color: $primary-color;
            }
        }
    }
}

.footer-nav-2 {
    li {
        display: inline-block;
        margin-left: -4px;
        margin-right: 25px;
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
        a {
            color: $light;
            display: block;
            font-weight: 400;
            text-transform: uppercase;
            &:hover {
                color: $primary-color;
            }
        }
    }
}

#go-top {
    bottom: 0;
    color: $light;
    font-size: 30px;
    height: 82px;
    line-height: 82px;
    position: fixed;
    right: 0;
    text-align: center;
    width: 82px;
    z-index: 50;
    @include transform(translate(100%, 0px));
    &:after {
        border-color: transparent #444 #444 transparent;
        border-style: solid;
        border-width: 41px;
        bottom: 0;
        content: "";
        position: absolute;
        right: 0;
        z-index: -1;
        @include transition(all 0.3s ease 0s);
    }
    &:hover::after {
        border-color: transparent $primary-color $primary-color transparent;
    }
    &.active {
        @include transform(translate(0px));
    }
    i {
        bottom: 14px;
        position: absolute;
        right: 16px;
    }
}
